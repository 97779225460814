import React from "react";
import { toastWarning } from "./Toast";

export const VALID_FILE_SIZE = 150582912;

const getShortName = (source, size = 35) => {
    return source?.length > size ? source?.slice(0, size - 1) + "…" : source;
};

const capitalizeFirstLetter = (str) => {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
};

const replaceUnderLine = (str = "") => capitalizeFirstLetter(str.split("_").join(" "));

const sizeInMB = (sizeInBytes) => (sizeInBytes / (1024 * 1024)).toFixed(2);

const IMAGE_EXTS = [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "tiff",
    "svg",
    "JPEG",
    "JPG",
    "PNG",
    "GIF",
    "TIFF",
    "SVG",
    "webp",
];
const DOC_EXTS = ["doc", "docx", "DOC", "DOCX"];
const XLS_EXTS = ["xls", "xlsx", "XLS", "XLSX"];
const PDF_EXTS = ["pdf", "PDF"];

const isValidFile = (file, type, validSize = VALID_FILE_SIZE) => {
    let ext = file.name.split(".").pop();
    if (type === "PRODUCT_DESIGN" || type === "REFERENCE_IMAGE" || type === "IMAGE") {
        if (IMAGE_EXTS.includes(ext) && file.size < validSize) {
            return true;
        } else {
            toastWarning("Please ensure proper extension of the file");
        }
    } else if (type === "image") {
        if (!IMAGE_EXTS.includes(ext)) {
            toastWarning("Please ensure proper extension of the file");
        } else if (file.size > validSize) {
            toastWarning(`Please upload file less than ${sizeInMB(validSize)} mb`);
        } else {
            return true;
        }
    } else if (type === "imageAndPdf") {
        if (!(IMAGE_EXTS.includes(ext) || PDF_EXTS.includes(ext))) {
            toastWarning("Please ensure proper extension of the file");
        } else if (file.size > validSize) {
            toastWarning(`Please upload file less than ${sizeInMB(validSize)} mb`);
        } else {
            return true;
        }
    } else {
        if (
            (IMAGE_EXTS.includes(ext) ||
                ext === "xls" ||
                ext === "xlsx" ||
                ext === "docx" ||
                ext === "doc" ||
                ext === "eps" ||
                ext === "ai" ||
                ext === "pdf" ||
                ext === "ppt" ||
                ext === "exe" ||
                ext === "pptx") &&
            file.size < validSize
        ) {
            return true;
        } else {
            toastWarning("Please ensure proper extension of the file");
        }
    }
    return false;
};

const isPageReachBottom = () => {
    const html = document.documentElement;
    const footerHeight = document.querySelector("footer").offsetHeight;
    const windowHeight =
        "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const windowBottom = windowHeight + window.pageYOffset;
    const scrollThreshold = html.scrollHeight - footerHeight;

    return windowBottom >= scrollThreshold;
};

export {
    getShortName,
    capitalizeFirstLetter,
    replaceUnderLine,
    sizeInMB,
    IMAGE_EXTS,
    DOC_EXTS,
    XLS_EXTS,
    PDF_EXTS,
    isValidFile,
    isPageReachBottom,
};
