import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Http from "../../utils/Http";
import Select from "react-select";
import { BASE_URL } from "../../utils/constant";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toastError, toastSuccess } from "../../utils/Toast";

const AddAddress = ({ showAdd, setShowAdd, updateAddresses }) => {
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [countryIdShipping, setCountryIdShipping] = useState(null);
    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [cityIdShipping, setCityIdShipping] = useState(null);
    const [city, setCity] = useState("");
    const [cityShipping, setCityShipping] = useState("");
    const [locationBilling, setlocationBilling] = useState({
        locationType: "BILLING",
        city: null,
        state: "",
        zip: "",
        title: "",
        landMark: "",
        address: "",
        address2: "",
    });
    const [locationShipping, setlocationShipping] = useState({
        locationType: "SHIPPING",
        city: null,
        state: "",
        zip: "",
        title: "",
        landMark: "",
        address: "",
        address2: "",
    });
    const [isBillingSubmit, setIsBillingSubmit] = useState(false);
    const [isSameBilling, setIsSameBilling] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const id = userInfo?.id;

    const getCountryList = () => {
        Http.GET("getCountryList").then(({ data }) => {
            const formatData = data.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setCountryList(formatData);
        });
    };

    const getCityList = () => {
        Http.GET(`${BASE_URL}/api/location/country/${countryId}/city`, "", true).then(
            ({ data }) => {
                const formatData = data.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                }));
                setCityList(formatData);
            }
        );
    };

    const checkSameAddress = () => {
        setIsSameBilling(!isSameBilling);
        if (!isSameBilling) {
            setlocationShipping({
                ...locationBilling,
                locationType: "SHIPPING",
            });
            setCityIdShipping(cityId);
            setCityShipping(city);
            setCountryIdShipping(countryId);
        } else {
            setlocationShipping({
                locationType: "SHIPPING",
                city: null,
                state: "",
                zip: "",
                title: "",
                landMark: "",
                address: "",
                address2: "",
            });
            setCityIdShipping(null);
            setCityShipping("");
            setCountryIdShipping(null);
        }
    };

    const submitAddress = async (event) => {
        const formDataBilling = {
            ...locationBilling,
            countryId,
            cityId,
            city: city.label,
            locationType: "BILLING", // Set the location type to "BILLING"
        };

        const formDataShipping = {
            ...locationShipping,
            countryId: countryIdShipping,
            cityId: cityIdShipping,
            city: cityShipping.label,
            locationType: "SHIPPING", // Set the location type to "SHIPPING"
        };

        const submitBillingPromise = Http.POST(
            `${BASE_URL}/user/${id}/client/location`,
            formDataBilling,
            "",
            true
        );
        const submitShippingPromise = Http.POST(
            `${BASE_URL}/user/${id}/client/location`,
            formDataShipping,
            "",
            true
        );

        Promise.all([submitBillingPromise, submitShippingPromise])
            .then((results) => {
                const [billingResult, shippingResult] = results;
               // console.log( billingResult, shippingResult );
                toastSuccess("Successfully added Billing and Shipping addresses");
                // localStorage.setItem( "selectedBillingAddress", billingResult?.id );
                // localStorage.setItem( "selectedShippingAddress", shippingResult?.id );
                updateAddresses();
                setIsBillingSubmit(true);
                setShowAdd(false);
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request wasn't successful");
                }
            });
    };

    const handleClose = () => setShowAdd(false);

    useEffect(() => {
        getCountryList();
    }, []);

    useEffect(() => {
        if (countryId) {
            getCityList();
        }
    }, [countryId]);

    return (
        <>
            <Modal show={showAdd} onHide={handleClose} className="add-address-modal">
                <Modal.Header closeButton>
                    <h3 className="modal-title">Add address</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="address-container">
                        <div className="address-row">
                            <h3 className="regular-20 text-uppercase mb-3">Billing</h3>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="locationType" className="form-label mb-0">
                                            Location Type
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={locationBilling.locationType}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    state: event.target.value,
                                                }))
                                            }
                                        >
                                            <option value="BILLING" selected>
                                                Billing
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline no-shadow-react-select flex-fill mb-0 mb-3">
                                        <label htmlfor="countryId" className="form-label mb-0">
                                            Country
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCountryId(selectedOption.value);
                                                setCityId(null);
                                            }}
                                            name="country"
                                            options={countryList}
                                            isClearable={false}
                                            placeholder="Select a country..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="no-shadow-react-select form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="city" className="form-label mb-0">
                                            City
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCityId(selectedOption.value);
                                                setCity(selectedOption.label);
                                            }}
                                            name="city"
                                            value={cityList.find(
                                                (option) => option.value === cityId
                                            )}
                                            options={cityList}
                                            isDisabled={!countryId}
                                            isClearable={false}
                                            placeholder={
                                                !countryId
                                                    ? "Please select a country first"
                                                    : cityList.length > 0
                                                    ? "Select a city..."
                                                    : "No cities available"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="state" className="form-label mb-0">
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            name="state"
                                            value={locationBilling.state}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    state: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="zip" className="form-label mb-0">
                                            ZIP
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="zip"
                                            name="zip"
                                            value={locationBilling.zip}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    zip: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="title" className="form-label mb-0">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            name="title"
                                            value={locationBilling.title}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    title: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="landMark" className="form-label mb-0">
                                            Land Mark
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="landMark"
                                            name="landMark"
                                            value={locationBilling.landMark}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    landMark: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="address" className="form-label mb-0">
                                            Address
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationBilling.address}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    address: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="addressTwo" className="form-label mb-0">
                                            Address 2
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationBilling.address2}
                                            onChange={(event) =>
                                                setlocationBilling((prevLocation) => ({
                                                    ...prevLocation,
                                                    address2: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="form-check d-flex">
                                <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    defaultValue
                                    id="form2Example3c"
                                    name="agree"
                                    checked={isSameBilling}
                                    onChange={() => checkSameAddress()}
                                />
                                <label className="form-check-label" htmlFor="form2Example3">
                                    Is Billing location & Shipping location same?
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="address-container">
                        <div className="address-row">
                            <h3 className="regular-20 text-uppercase mb-3">Shipping</h3>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="locationType" className="form-label mb-0">
                                            Location Type
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={locationShipping.locationType}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    state: event.target.value,
                                                }))
                                            }
                                        >
                                            <option value="SHIPPING" selected>
                                                Shipping
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline no-shadow-react-select flex-fill mb-0 mb-3">
                                        <label htmlfor="countryId" className="form-label mb-0">
                                            Country
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCountryIdShipping(selectedOption.value);
                                                setCityIdShipping(null);
                                            }}
                                            name="country"
                                            value={countryList.find(
                                                (option) => option.value === countryIdShipping
                                            )}
                                            options={countryList}
                                            isClearable={false}
                                            placeholder="Select a country..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="no-shadow-react-select form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="city" className="form-label mb-0">
                                            City
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCityIdShipping(selectedOption.value);
                                                setCityShipping(selectedOption.label);
                                            }}
                                            name="city"
                                            value={cityList.find(
                                                (option) => option.value === cityIdShipping
                                            )}
                                            options={cityList}
                                            isDisabled={!countryIdShipping}
                                            isClearable={false}
                                            placeholder={
                                                !countryIdShipping
                                                    ? "Please select a country first"
                                                    : cityList.length > 0
                                                    ? "Select a city..."
                                                    : "No cities available"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="state" className="form-label mb-0">
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            name="state"
                                            value={locationShipping.state}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    state: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="zip" className="form-label mb-0">
                                            ZIP
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="zip"
                                            name="zip"
                                            value={locationShipping.zip}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    zip: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="title" className="form-label mb-0">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            name="title"
                                            value={locationShipping.title}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    title: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="landMark" className="form-label mb-0">
                                            Land Mark
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="landMark"
                                            name="landMark"
                                            value={locationShipping.landMark}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    landMark: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="address" className="form-label mb-0">
                                            Address
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationShipping.address}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    address: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="addressTwo" className="form-label mb-0">
                                            Address 2
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationShipping.address2}
                                            onChange={(event) =>
                                                setlocationShipping((prevLocation) => ({
                                                    ...prevLocation,
                                                    address2: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-2">
                        <button type="submit" className="button secondary" onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button green ms-3"
                            onClick={() => submitAddress()}
                        >
                            Submit
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddAddress;
