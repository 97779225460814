const PaymentGateWay = ({ payTrailResponse }) => (
  <div className="payment-methods mt-3">
    {payTrailResponse?.providers?.map((provider) => (
      <form key={provider.url} method="POST" action={provider.url}>
        {provider?.parameters.map(parameterToInput)}
        <button>
          <img
            src={provider.svg}
            alt="Provider Logo"
            width={100}
            height={100}
          />
        </button>
      </form>
    ))}
  </div>
);

const parameterToInput = (parameter) => (
  <input
    key={parameter.name}
    type="hidden"
    name={parameter.name}
    value={parameter.value}
  />
);

export default PaymentGateWay;
