import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-contents flex-wrap">
                    <div className="one-third">
                        <NavLink to="/">
                            <img src="/icons/Logo.svg" alt="logo" className="logo mb-3" />
                        </NavLink>
                        <p>Rebuild Techno OY (3382066-2)</p>
                        <p>
                            Leikosaarentie 5 D 64 , Vuosari 00980 ,
                            <br /> Helsinki
                        </p>
                        <p>+358442366885</p>
                        <p>info@rebuildtechno.com</p>
                    </div>
                    <div className="one-third">
                        <h3 className="widget-title">Useful Links</h3>
                        <ul>
                            <li>
                                <NavLink to="/about">About Us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/faq">FAQs</NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </li>
                            <li>
                                <NavLink to="/career">Career</NavLink>
                            </li>
                            {/*<li>Blog</li>*/}
                            {/*<li>
                                <NavLink to="/rt-return-warranty-policy">
                                    RT Return & Warranty Policy
                                </NavLink>
                            </li>*/}
                            <li>
                                <NavLink to="/faq?query=delivery">
                                    Delivery
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/faq?query=return">
                                    Return
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/faq?query=refund">
                                    Refund
                                </NavLink>
                            </li>


                            <li>
                                <NavLink to="/faq?query=payment">
                                    Payment Methods
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="one-third">
                        <h3 className="widget-title">Compare Model</h3>
                        <ul>
                            <li>
                                <NavLink to="/product/compare">Products</NavLink>
                            </li>
                        </ul>
                        <div className="social-icons">
                            <h3 className="widget-title mt-4 pt-3">Follow us on</h3>
                            <ul className="d-flex">
                                <li className="me-3">
                                    {" "}
                                    <NavLink
                                        to="https://www.facebook.com/rebuildtechno"
                                        target="_blank"
                                    >
                                        <img src="/icons/facebook.svg" alt="facebook" />
                                    </NavLink>
                                </li>
                                <li className="me-3">
                                    {" "}
                                    <NavLink
                                        to="https://www.linkedin.com/company/rebuildtechno"
                                        target="_blank"
                                    >
                                        <img src="/icons/linkedin.svg" alt="linkedin" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="one-third payments">
                        <h3 className="widget-title">Payment Systems</h3>
                        <div className="images d-flex">
                            <img src="/icons/paytrial_banner.svg" alt="paytrial" />
                        </div>
                    </div>

                    <div className="one-third shipping">
                        <h3 className="widget-title">Fast Shipping</h3>
                        <div className="images d-flex">
                            <img src="/icons/posti.png" className="posti" alt="posti" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="copywrite">
                <p>Copyright : 2023-2024, rebuildtechno.com - All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
