import React, { useEffect, useState } from "react";
import AddressBook from "../profile/AddressBook";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import Modal from "react-bootstrap/Modal";
import { CART_SESSION_NAME } from "../../utils/constant";
import PaymentGateWay from "../payment/PaymentGateWay";

const Checkout = (children, func) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const location = useLocation();
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [vatAmount, setVatAmount] = useState(0);
    const [clientName, setClientName] = useState(userInfo?.name);
    const [email, setEmail] = useState(userInfo?.email);
    const [phone, setPhone] = useState(userInfo?.phone);
    const [itemList, setItemList] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [show, setShow] = useState(false);
    const [error, setError] = useState({});
    const [productQuestionList, setProductQuestionList] = useState([]);
    const [paymentGateWayData, setPaymentGateWayData] = useState([]);

    const navigate = useNavigate();
    const deliverCharge = 17.0;
    const billingId = localStorage.getItem("selectedBillingAddress");
    const shippingId = localStorage.getItem("selectedShippingAddress");
    const cartSession = localStorage.getItem(CART_SESSION_NAME);

    const getCartItems = () => {
        if (cartSession) {
            Http.GET("getCartItems", cartSession)
                .then((response) => {
                    const data = response.data;
                    setCartItems(data);
                    calculateTotal(data);
                })
                .catch((response) => {
                    toastError(response.message);
                });
        }
    };

    const calculateTotal = (items) => {
        let price = 0;
        items.forEach((product) => {
            price += product.price;
        });
        setTotal(price.toFixed(2));
        const vatPercentage = 0.24; // 24% VAT
        const calculatedVat = price * vatPercentage;
        setVatAmount(calculatedVat.toFixed(2));
    };

    const handleClose = () => setShow(false);

    const validate = () => {
        const MAX_EMAIL_LENGTH = 100;
        const errors = {};
        const storedBillingId = localStorage.getItem("selectedBillingAddress");
        const storedShippingId = localStorage.getItem("selectedShippingAddress");

        if( !storedBillingId || storedBillingId === "null" || storedBillingId === undefined ) {
            toastError( "please select/ add billing address" );
            return false;
        }

        if( !storedShippingId || storedShippingId === "null" || storedShippingId === undefined ) {
            toastError( "please select/add shipping address" );
            return false;
        }

        if (!clientName) {
            errors.clientName = "Client Name is required.";
        }
        if (!email) {
            errors.email = "Email is required.";
        } else {
            if (email.length > MAX_EMAIL_LENGTH) {
                errors.email = `Email should not exceed ${MAX_EMAIL_LENGTH} characters.`;
            } else {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(email)) {
                    errors.email = "Please enter a valid email format.";
                }
            }
        }
        if (!phone) {
            errors.phone = "Phone is required.";
        }
        setError(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        const storedBillingId = localStorage.getItem("selectedBillingAddress");
        const storedShippingId = localStorage.getItem("selectedShippingAddress");

        if (validate()) {
            const formData = {
                clientName: clientName,
                email: email,
                phone: phone,
                billingId: storedBillingId,
                shippingId: storedShippingId,
                promoCode: promoCode,
                deliveryCharge: deliverCharge,
                itemList: itemList,
            };

            Http.POST("placeOrder", formData)
                .then(({ data }) => {
                    let response = data?.payload;
                    if( response?.paymentResponse ) {
                        setPaymentGateWayData( response?.paymentResponse );
                    }else {
                        toastSuccess("Successfully Placed an Order");
                        navigate("/profile?activeTab=ORDER");
                    }
                })
                .catch(({ response }) => {
                    if (response && response.data && response.data.message) {
                        toastError(response.data.message);
                    } else {
                        toastError("Request wasn't successful");
                    }
                });
        }
    };

    const testPayment = async () => {
        Http.POST("paymentTest", {})
            .then( ( {data} ) => {
                // console.log( data.payload );
                setPaymentGateWayData( data.payload );

            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request wasn't successful");
                }
            });
    };

    const openQuestionAnswerModal = (productId, cartId) => {
        getProductQuestionAnswer(cartId);
        setShow(true);
    };

    const getProductQuestionAnswer = (cartId) => {
        Http.GET("productQuestionAnswerList", cartId)
            .then(({ data }) => {
                setProductQuestionList(data);
            })
            .catch((error) => {
                // Handle error
            });
    };

    useEffect(() => {
        getCartItems();
    }, []);

    useEffect(() => {
        const updatedItemList = cartItems.map((item) => {
            return { cartId: item?.id };
        });
        setItemList(updatedItemList);
    }, [cartItems]);

    return (
        <div className="checkout-page">
            <div className="container section-padding">
                <div className="checkout-container">
                    <div className="left-half">
                        <div className="contact-details">
                            <div className="contact-details-info">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="regular-16 mb-2">Contact details</h2>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-outline flex-fill mb-0 mb-3">
                                            <label htmlfor="fname" className="form-label mb-0">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fname"
                                                name="fname"
                                                value={clientName}
                                                onChange={(e) => setClientName(e.target.value)}
                                            />
                                            {error.clientName && (
                                                <p className="text-danger regular-12">
                                                    {error.clientName}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-outline flex-fill mb-0 mb-3">
                                            <label htmlfor="email" className="form-label mb-0">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {error.email && (
                                                <p className="text-danger regular-12">
                                                    {error.email}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-outline flex-fill mb-0 mb-3">
                                            <label htmlfor="phone" className="form-label mb-0">
                                                Phone
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="phone"
                                                name="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                            {error.phone && (
                                                <p className="text-danger regular-12">
                                                    {error.phone}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AddressBook />
                        <div className="added-cart-items cart-page">
                            {cartItems.length > 0 &&
                                cartItems.map((item) => (
                                    <div
                                        className="item d-flex align-items-center justify-content-between"
                                        key={item?.id}
                                    >
                                        <div className="cart-items-info d-flex align-items-center">
                                            <div className="image">
                                                <img
                                                    src={
                                                        item?.productResponse?.featureImageResponse
                                                            ?.docUrl
                                                    }
                                                    alt="feature-image"
                                                />
                                            </div>
                                            <div className="description">
                                                <span>{item?.productResponse?.name}</span>
                                                <span>
                                                    {item?.productResponse?.brandResponse?.name}
                                                </span>
                                            </div>
                                            <div className="quantity">
                                                <p className="regular-14">Qty: {item?.quantity}</p>
                                            </div>
                                            <div className="total-price">
                                                €{item?.price.toFixed(2)}
                                            </div>
                                        </div>

                                        {(item?.productResponse?.categoryResponse?.type ===
                                            "SELL" ||
                                            item?.productResponse?.categoryResponse?.type ===
                                                "REPAIR") && (
                                            <div className="answers">
                                                <button
                                                    className="button secondary"
                                                    onClick={() =>
                                                        openQuestionAnswerModal(
                                                            item?.productResponse?.id,
                                                            item?.id
                                                        )
                                                    }
                                                >
                                                    Answers
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                className="button green mt-3 mb-2"
                                onClick={() => navigate("/cart")}
                            >
                                UPDATE CART
                            </button>
                        </div>
                    </div>
                    <div className="right-half">
                        <div className="confirm-order">
                            <p className="regular-14">Discount and Payment</p>
                            <div className="form-outline promo flex-fill mb-0 mb-3 mt-2">
                                <label htmlfor="promo" className="form-label mb-0">
                                    Promo code
                                </label>

                                <div className="d-flex align-items-center">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="promo"
                                        name="promo"
                                        onChange={(e) => setPromoCode(e.target.value)}
                                    />
                                    <input type="submit" className="submit-promo" value="Apply" />
                                </div>
                            </div>
                            <div className="subtotal-info">
                                <div className="subtotal-item mb-1 d-flex justify-content-between">
                                    <p className="regular-14">Subtotal:</p>
                                    <p className="regular-14 weight-500"> €{total}</p>
                                </div>
                                <div className="subtotal-item mb-1 d-flex justify-content-between">
                                    <p className="regular-14">Delivery charge:</p>
                                    <p className="regular-14 weight-500">
                                        {" "}
                                        €{deliverCharge.toFixed(2)}
                                    </p>
                                </div>
                                {/*<div className="subtotal-item mb-2 d-flex justify-content-between">*/}
                                {/*    <p className="regular-14">VAT:</p>*/}
                                {/*    <p className="regular-14 weight-500">€{vatAmount}</p>*/}
                                {/*</div>*/}
                                <div className="subtotal-item pt-1 border-top-solid d-flex justify-content-between">
                                    <p className="regular-14 weight-600">Total:</p>
                                    <p className="regular-14 weight-600">
                                        $
                                        {(
                                            parseFloat(total) +
                                            parseFloat(deliverCharge)
                                            // + parseFloat(vatAmount)
                                        ).toFixed(2)}
                                    </p>
                                </div>
                                <button className="button green mt-3 w-100" onClick={handleSubmit}>
                                    CONFIRM ORDER
                                </button>

                                <PaymentGateWay payTrailResponse={paymentGateWayData}>

                                </PaymentGateWay>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Answered question modal */}
            <Modal show={show} onHide={handleClose} className="add-address-modal">
                <Modal.Header closeButton>
                    <h3 className="modal-title">Answered questions</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="all-questions">
                        {productQuestionList?.map((item) => (
                            <div
                                className="single-question mb-3"
                                key={"product_question_" + item?.id}
                            >
                                <h3 className="regular-16">{item?.title}</h3>
                                <p className="regular-14 dark_01">
                                    <span className="dark_02">Ans:</span> {item?.answer}
                                </p>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Checkout;
