import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { BASE_URL, CART_SESSION_NAME } from "../../utils/constant";
import { useSelector, useDispatch } from "react-redux";
import removeOne from "../../actions/action";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const cartSession = localStorage.getItem(CART_SESSION_NAME);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const isLogin = () => {
    return !!token;
  };

  const getCartItems = () => {
    if (cartSession) {
      Http.GET("getCartItems", cartSession)
        .then((response) => {
          const data = response.data;
          setCartItems(data);
          calculateTotal(data);
        })
        .catch((response) => {
          toastError(response.message);
        });
    }
  };

  const calculateTotal = (items) => {
    let price = 0;
    items.forEach((product) => {
      price += product.price;
    });
    setTotal(price?.toFixed(2));
  };

  const updateCart = (id, newQuantity) => {
    const body = {
      quantity: newQuantity,
    };

    Http.PUT("updateCart", body, id)
      .then((response) => {
        toastSuccess("Successfully updated");
        const cartResponse = response.data.payload;
        // Update the cart item in the cartItems array
        const updatedItems = cartItems.map((item) => {
          if (item.id === id) {
            return cartResponse;
          }
          return item;
        });
        setCartItems(updatedItems);
        calculateTotal(updatedItems); // Recalculate the total after updating the item
      })
      .catch((response) => {
        toastError(response.message);
      });
  };

  const deleteCartItem = (id) => {
    Http.DELETE(
      `${BASE_URL}/api/cart/items/${id}/session/${cartSession}`,
      "",
      "",
      true
    )
      .then((response) => {
        dispatch(removeOne({ count: -1 }));
        getCartItems();
      })
      .catch((err) => {
        toastError(err.response.data.message);
      });
  };

  const handleIncrement = (id) => {
    const item = cartItems.find((item) => item.id === id);
    const newQuantity = item.quantity + 1;
    updateCart(id, newQuantity);
  };

  const handleDecrement = (id) => {
    const item = cartItems.find((item) => item.id === id);
    const newQuantity = item.quantity - 1;
    if (newQuantity < 1) {
      deleteCartItem(id);
    } else {
      updateCart(id, newQuantity);
    }
  };

  const goToProduct = (slug) => {
    //navigate(`/products/${slug}`,{replace : true});
    window.location.href = `/products/${slug}`;
  };

  const redirectToLogin = (url) => {
    sessionStorage.setItem("lastVisitedUrl", url);
    navigate("/login");
  };

  const handleProceedToCheckout = () => {
    if (isLogin()) {
      navigate("/checkout", { state: { cartItems, total } });
    } else {
      redirectToLogin("/checkout");
    }
  };

  useEffect(() => {
    getCartItems();
  }, []);

  return (
    <div className="cart-page">
      <div className="container section-padding">
        <h1 className="regular-28 text-uppercase">Cart</h1>
        <div className="shopping-cart">
          <div className="title">Shopping Bag</div>
          {cartItems.length > 0 &&
            cartItems.map((item) => (
              <div className="item" key={item.id}>
                <div className="buttons">
                  <span
                    className="delete-btn"
                    onClick={() => deleteCartItem(item.id)}
                  />
                </div>
                <div
                  className="image cursor-pointer"
                  onClick={() =>
                      goToProduct(item?.productResponse?.slug)
                  }
                >
                  <img
                    src={item?.productResponse?.featureImageResponse?.docUrl}
                    alt="feature-image"
                  />
                </div>
                <div
                  className="description cursor-pointer"
                  onClick={() =>
                      goToProduct( item?.productResponse?.slug)
                  }
                >
                  <span>{item?.productResponse?.name}</span>
                  <span>{item?.productResponse?.brandResponse?.name}</span>
                </div>
                <div className="quantity">
                  <button
                    className="minus-btn"
                    type="button"
                    name="button"
                    onClick={() => handleDecrement(item.id)}
                  >
                    <img src="/icons/minus.svg" alt="" />
                  </button>
                  <input
                    type="text"
                    name="name"
                    value={item?.quantity}
                    readOnly
                  />
                  <button
                    className="plus-btn"
                    type="button"
                    name="button"
                    onClick={() => handleIncrement(item.id)}
                  >
                    <img src="/icons/plus.svg" alt="" />
                  </button>
                </div>
                <div className="total-price">€{item?.price.toFixed(2)}</div>
              </div>
            ))}
        </div>
        <div className="total-box">
          <div>
            <button
              className="button green shopping w-100"
              onClick={() => navigate("/buy/main/0")}
            >
              CONTINUE SHOPPING
            </button>
          </div>
          <div className="total-box-content">
            <div className="heading">
              <h3 className="regular-20">Cart totals</h3>
            </div>
            <div className="subtotal-info">
              <div className="subtotal-item">
                <p className="regular-16 weight-500">Subtotal:</p>
                <p className="regular-16"> €{total}</p>
              </div>
              <div className="subtotal-item">
                <p className="regular-16 weight-500">Total:</p>
                <p className="regular-16">€{total}</p>
              </div>
              <button
                className="button green mt-3 mb-2 w-100"
                onClick={handleProceedToCheckout}
              >
                PROCEED TO CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
