import React from "react";
import { useState } from "react";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PaymentGateWay from "../payment/PaymentGateWay";
import {BASE_URL} from "../../utils/constant";

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState([]);
  const [paymentGateWayData, setPaymentGateWayData] = useState([]);
  const navigate = useNavigate();

  console.log( paymentGateWayData );

  const getMyOrders = () => {
    Http.GET("myOrder")
      .then((response) => {
        const data = response.data;
        setOrders(data);
      })
      .catch((response) => {
        toastError(response.message);
      });
  };

  const rePayment = ( order ) => {
      setOrder( order );

    Http.GET(BASE_URL + "/api/order/" + order.id + "/repayment", "", true)
        .then(({data}) => {
            let response = data?.payload;
            console.log( response );
            if( response?.paymentResponse ) {
                setPaymentGateWayData( response?.paymentResponse );
            }
        })
        .catch((response) => {
          toastError(response.message);
        });

  }

  useEffect(() => {
    getMyOrders();
  }, []);

  return (
    <div className="order-list">
      {orders.length > 0 ? (
        orders.map((item, index) => (
          <div className="single-order" key={"order_"+item.id}>
            <div className="order-header d-flex justify-content-between">
              <p className="dark_02">
                Order:{" "}
                <span className="dark_01 weight-500ar">{item?.invoiceNo}</span>{" "}
                <span className="dark_01 weight-500">
                  ({item?.amount.toFixed(2)})
                </span>
              </p>

              <div>
                {item?.type === "BUY" && item?.status === "PENDING" && (
                  <button className="button green" onClick={() => rePayment( item ) }>Pay</button>
                )}
                <button
                  className="button green ms-2"
                  onClick={() => navigate(`/review/${item?.id}`)}
                >
                  Review
                </button>
                  { item.id === order.id &&
                      (
                          <PaymentGateWay payTrailResponse={paymentGateWayData}>

                          </PaymentGateWay>
                      )
                  }

              </div>
            </div>
            <div className="order-body">
              <div className="serial">
                <p>{index + 1}</p>
              </div>
              <div className="prodict-list">
                {item?.itemResponseList &&
                  item?.itemResponseList.map((product) => (
                    <div className="product">
                      <div className="photo d-flex align-items-center">
                        <img
                          src={
                            product?.productResponse?.featureImageResponse
                              ?.docUrl
                          }
                          alt="product"
                        />
                        <p class="regular-14">
                          {product?.productResponse?.name}
                        </p>
                      </div>

                      <div className="quantity">
                        <p class="regular-14">
                          {" "}
                          <span className="dark_02">Qty:</span>{" "}
                          {product?.quantity}
                        </p>
                      </div>
                      <div className="amount">
                        <p class="regular-14">${product?.amount}</p>
                      </div>
                      <div className="status">
                        <span></span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="image"></div>
            </div>
          </div>
        ))
      ) : (
        <p className="empty-state">No order found</p>
      )}
    </div>
  );
};

export default MyOrder;
