import React from "react";
import { FiFacebook } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";

const AboutUs = () => {
    return (
        <div className="container section-padding mb-5">
            <div className="about">
                <h1 className="medium-20 mb-2">Story About Rebuild Techno</h1>
                <p className="mb-3">
                    Nowadays earth is polluted by e waste gradually. We should reuse old products
                    for reducing e waste and safe the world from pollution. As per innovative idea,
                    we build up our refurbish electronic company (Rebuild Techno) for reducing e
                    waste and safe the world.
                </p>
                <p className="mb-3">
                    Waste from electrical and electronic equipment includes a large range of
                    devices such as computers, refrigerators, mobile phones, etc. at the end of
                    their life. This type of waste contains a complex mixture of materials, some of
                    which are hazardous. These can cause major environmental and health problems if
                    the discarded devices are not managed properly. In addition, modern electronics
                    contain rare and expensive resources, which can be recycled and re-used if the
                    waste is effectively managed.
                </p>
                <p className="mb-3">
                    Improving the collection, treatment, and recycling of electrical and electronic
                    equipment (EEE) at the end of their life can:
                </p>
                <ol type="I" className="mt-2">
                    <li>Improve sustainable production and consumption</li>
                    <li>Increase resource efficiency</li>
                    <li>Contribute to the circular economy</li>
                </ol>
            </div>

            <div className="why-choose">
                <h1 className="medium-20 mt-4 mb-3">
                    Why choose Rebuild Techno for refurbished device?
                </h1>
                <ol type="1" className="mt-2">
                    <li className="mb-2">
                        <span className="weight-600">Fully Checked Devices:</span> Every iPhone,
                        iPad, Apple Watch, and other device we offer goes through a comprehensive
                        <span className="weight-600"> multiple</span> point device diagnostic check to
                        guarantee every component is functioning as it should be since we only want
                        to provide the highest quality refurbished devices.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">Free 12 Month Warranty:</span> We recognize
                        that you might be hesitant to invest money on a refurbished smartphone. Yet
                        for that reason, we offer a complete 12-month warranty with every
                        transaction. Having the assurance that we will fix any issues with your
                        gadget gives you piece of mind.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">Fast, Free Delivery:</span> We are aware that
                        you desire your purchase as soon as you can after making it. Your gadget
                        will be dispatched to you through Royal Mail on our quick and free delivery
                        service as soon as it has passed our checklist to guarantee that it is
                        error-free and ready for use.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">Fundamentally Cost-effective:</span>{" "}
                        Refurbishing a mobile phone is usually less expensive than buying a
                        brand-new phone. This makes it a great option for people who want to save
                        money or are on a tight budget.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">Frequently Reduce carbon footprint:</span> Take
                        steps to reduce your carbon footprint, such as walking or biking instead of
                        driving, using public transportation, and reducing your energy consumption.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">
                            Faithfully Support eco-friendly businesses:{" "}
                        </span>{" "}
                        Choose products and services from businesses that are committed to
                        sustainability and reducing their impact on the environment.
                    </li>
                    <li className="mb-2">
                        <span className="weight-600">Fairly Build up circular Economy: </span>At our
                        company, we are dedicated to promoting and actively participating in the
                        development of a circular economy. We understand the importance of
                        minimizing waste and maximizing the lifespan of products to create a
                        sustainable future.
                    </li>
                </ol>
            </div>
            <div className="our-team mt-4">
                <h1 className="medium-20 mb-2 text-center mb-3">Our Team</h1>
                <div className="team-members chairman">
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Alamgir Hossain</h5>
                            <h6 class="designation">Chairman & Founder</h6>
                            <p className="department">(Normal repair)</p>
                            <p>Operations Planning and Process Development</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-members processing-consultant">
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Saiful Islam</h5>
                            <h6 class="designation">Planning and Processing Consultant</h6>
                            <p className="department">(Inbound)</p>
                            <p>Operations Planning and Process Development</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Sofiqul Islam</h5>
                            <h6 class="designation">Planning and Processing Consultant</h6>
                            <p className="department">(Normal repair)</p>
                            <p>Operations Planning and Process Development</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-members consultant">
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Sajib Hosen</h5>
                            <h6 class="designation">Tech Consultant</h6>
                            <p className="department">(IT)</p>
                            <p>
                                Arranging training for employees, software testing, find
                                innovative solutions{" "}
                            </p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Mizanur Rahman</h5>
                            <h6 class="designation">Tech Consultant</h6>
                            <p className="department">(IT)</p>
                            <p>
                                Arranging training for employees, software testing, find
                                innovative solutions{" "}
                            </p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Motiur Rahman</h5>
                            <h6 class="designation">Warehouse Consultant</h6>
                            <p className="department">(Warehouse)</p>
                            <p>Assembling orders and spare parts</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="team-members processing-consultant">
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Md Rakib Islam</h5>
                            <h6 class="designation">Frontend Developer</h6>
                            <p className="department">(IT)</p>
                            <p>Operations Planning and Development</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="single-member">
                        <div className="photo">
                            <img
                                src="/icons/default-profile.svg"
                                alt="wrapkit"
                                class="img-fluid rounded-circle"
                            />
                        </div>
                        <div className="content">
                            <h5 class="name">Mahmudul Hasan Ullash</h5>
                            <h6 class="designation">Jr. SQA Engineer</h6>
                            <p className="department">(IT)</p>
                            <p>System Testing and Process Development</p>
                            <div className="icons">
                                <ul>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiFacebook />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <FiLinkedin />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
