import React, { useState, useEffect } from "react";
import AddAddress from "./AddAddress";
import AddressBook from "./AddressBook";
import { useNavigate, useSearchParams } from "react-router-dom";
import MyOrder from "./MyOrder";
import PersonalInfo from "./PersonalInfo";

const Profile = () => {
    const [activeMenu, setActiveMenu] = useState("ADDRESS");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onTabChange = (tabType) => {
        setActiveMenu(tabType);
        navigate(`?activeTab=${tabType}`);
    };

    const checkActiveTab = () => {
        let activeTabName = searchParams.get("activeTab");

        if (activeMenu !== activeTabName && searchParams.toString() !== "") {
            setActiveMenu(activeTabName);
        } else {
            setActiveMenu("ADDRESS");
        }
    };

    useEffect(() => {
        checkActiveTab();
    }, []);

    return (
        <div className="container section-padding">
            <div className="profile-layout">
                <div className="leftbar">
                    <ul className="profile-menu">
                        <li
                            className={`${activeMenu === "ADDRESS" ? "active" : ""}`}
                            onClick={() => {
                                onTabChange("ADDRESS");
                            }}
                        >
                            Address
                        </li>
                        <li
                            className={`${activeMenu === "ORDER" ? "active" : ""}`}
                            onClick={() => {
                                onTabChange("ORDER");
                            }}
                        >
                            Order
                        </li>
                        <li
                            className={`${activeMenu === "PERSONALINFO" ? "active" : ""}`}
                            onClick={() => {
                                onTabChange("PERSONALINFO");
                            }}
                        >
                            Personal Info
                        </li>
                    </ul>
                </div>
                <div className="right-content">
                    {activeMenu === "ADDRESS" && <AddressBook />}
                    {activeMenu === "ORDER" && <MyOrder />}
                    {activeMenu === "PERSONALINFO" && <PersonalInfo />}
                </div>
            </div>
        </div>
    );
};

export default Profile;
