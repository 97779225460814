import React, { useState } from "react";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { NavLink, useNavigate } from "react-router-dom";

const Register = () => {
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        fullName: "",
        nickname: "",
        phone: "",
        email: "",
        status: "ACTIVE",
        password: "",
        retypePassword: "",
        //agree: false,
    });
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormData({ ...formData, [name]: newValue });
    };

    const validate = () => {
        const errors = {};

        const MAX_EMAIL_LENGTH = 100;
        if (!formData.fullName) {
            errors.fullName = "Your Full Name is required.";
        }
        if (!formData.nickname) {
            errors.nickname = "Your Nick Name is required.";
        }
        if (!formData.phone) {
            errors.phone = "Your Phone is required.";
        }
        if (!formData.email) {
            errors.email = "Your Email is required.";
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email)) {
                errors.email = "Please enter a valid email format.";
            } else if (formData.email.length > MAX_EMAIL_LENGTH) {
                errors.email = `Email should not exceed ${MAX_EMAIL_LENGTH} characters.`;
            } else {
                const emailUsernamePattern = /^1@gmail\.com$/;
                if (emailUsernamePattern.test(formData.email)) {
                    errors.email = "Invalid email. Please enter a valid email.";
                }
            }
        }
        if (!formData.password) {
            errors.password = "Password is required.";
        }
        if (!formData.retypePassword) {
            errors.retypePassword = "Retype Password is required.";
        } else if (formData.password !== formData.retypePassword) {
            errors.retypePassword = "Passwords do not match.";
        }

        setError(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validate()) {
            Http.POST("createClient", formData)
                .then(({ data }) => {
                    toastSuccess(data.message);
                    navigate("/login/");
                })
                .catch(({ response }) => {
                    console.log(response.data.message);
                    let message = "Something went wrong!"; // Default message
                    try {
                        // Attempt to parse the response data as JSON
                        let data = JSON.parse(response.data.message);
                        if (data?.length > 0 && data[0]?.defaultMessage != undefined) {
                            message = data[0]?.defaultMessage;
                        }
                    } catch (error) {
                        // If parsing as JSON fails, use the default message
                    }
                    toastError(message);
                });
        }
    };

    return (
        <section className="register-section">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-12 px-0 login-info-container">
                        <div className="card text-black border-0">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="login-thambnail">
                                        <img src="/images/login.jpg" alt="login" />
                                    </div>
                                    <div className="form-info">
                                        <p className="text-center d-flex align-items-center justify-content-between h2 fw-bold  mx-1 mx-md-4 mt-4">
                                            <span>Sign up</span>
                                            <img
                                                src="/icons/Logo.svg"
                                                alt="logo"
                                                className="logo"
                                            />
                                        </p>
                                        <form
                                            className="form-list mx-1 mx-md-4"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="fullName"
                                                    >
                                                        Your Full Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="fullName"
                                                        className="form-control"
                                                        name="fullName"
                                                        value={formData.fullName}
                                                        onChange={handleChange}
                                                    />
                                                    {error.fullName && (
                                                        <p className="text-danger regular-12">
                                                            {error.fullName}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="nickName"
                                                    >
                                                        Your Nick Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="nickName"
                                                        className="form-control"
                                                        name="nickname"
                                                        value={formData.nickname}
                                                        onChange={handleChange}
                                                    />
                                                    {error.nickname && (
                                                        <p className="text-danger regular-12">
                                                            {error.nickname}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="fullName"
                                                    >
                                                        Your Phone
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        id="phone"
                                                        name="phone"
                                                        className="form-control"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                    />
                                                    {error.phone && (
                                                        <p className="text-danger regular-12">
                                                            {error.phone}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="form3Example3c"
                                                    >
                                                        Your Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        id="form3Example3c"
                                                        className="form-control"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    {error.email && (
                                                        <p className="text-danger regular-12">
                                                            {error.email}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="form3Example4c"
                                                    >
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        id="form3Example4c"
                                                        className="form-control"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                    {error.password && (
                                                        <p className="text-danger regular-12">
                                                            {error.password}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="form3Example4cd"
                                                    >
                                                        Repeat your password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        id="form3Example4cd"
                                                        className="form-control"
                                                        name="retypePassword"
                                                        value={formData.retypePassword}
                                                        onChange={handleChange}
                                                    />
                                                    {error.retypePassword && (
                                                        <p className="text-danger regular-12">
                                                            {error.retypePassword}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                <button type="submit" className="button green">
                                                    Register
                                                </button>
                                            </div>
                                            <p className="mx-4">
                                                Already have a account?{" "}
                                                <NavLink to={"/login"}>Login</NavLink>{" "}
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;
