import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CategorySlider from "../slider/CategorySlider";
import CustomerReview from "../slider/CustomerReview";
import ProductSlider from "../slider/ProductSlider";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";

const Home = () => {
    const navigate = useNavigate();
    const [totalReview, setTotalReview] = useState({});

    const goCatpage = (type) => {
        navigate(`/${type}/main/0`);
    };

    const goToCompareProduct = () => {
        navigate(`/product/compare`);
    };

    const getTotalReview = () => {
        Http.GET("getTotalReview")
            .then((response) => {
                const data = response.data;
                setTotalReview(data);
            })
            .catch((response) => {
                toastError(response.message);
            });
    };

    useEffect(() => {
        getTotalReview();
    }, []);

    return (
        <>
            <section className="best-selling-slider-section">
                <div className="container section-padding">
                    <div className="heading d-flex justify-content-between align-items-centr pb-3">
                        <h2 className="regular-28 text-uppercase">Exclusive Product</h2>

                        <button className="button text-only" onClick={() => goCatpage("buy")} >
                            <span className="regular-16">{`View All >>`} </span>
                        </button>
                    </div>
                    <ProductSlider />
                </div>
            </section>
            <section className="main-category-section">
                <div className="container section-padding">
                    <div className="catergory-row">
                        <div className="single-category d-flex" onClick={() => goCatpage("buy")}>
                            <div className="image">
                                <img src="/images/buy.jpg" alt="buy" />
                            </div>
                            <div className="info">
                                <h2 className="regular-28 mb-2">Buy</h2>
                                <p>Buy refurbish for today, save money for tomorrow.</p>

                                {/*Buy:
                                1. Buy with confidence, every time.
                                2. Buy refurbish for today, save money for tomorrow.
                                3.Refurbished phones: save now, use
                                4.Buy efficiently, quickly and hassle-free*/}
                            </div>
                        </div>
                        <div className="single-category d-flex" onClick={() => goCatpage("sell")}>
                            <div className="image">
                                <img src="/images/sell.jpg" alt="sell" />
                            </div>
                            <div className="info">
                                <h2 className="regular-28 mb-2">Sell</h2>
                                <p>Sell your phone, upgrade your life.</p>

                                {/*Sell:
                                1. Turn your phone into cash.
                                Sell your phone and get paid.
                                Sell smart, get cash fast.
                                Sell your phone, upgrade your life*/}
                            </div>
                        </div>

                        <div className="single-category d-flex" onClick={() => goToCompareProduct()}>
                            <div className="image">
                                <img src="/images/repair.jpeg" alt="repair" />
                            </div>
                            <div className="info">
                                <h2 className="regular-28 mb-2">Compare Product</h2>
                                <p></p>

                            </div>
                        </div>

                        {/*<div className="single-category d-flex" onClick={() => goCatpage("repair")}>
                            <div className="image">
                                <img src="/images/repair.jpeg" alt="repair" />
                            </div>
                            <div className="info">
                                <h2 className="regular-28 mb-2">Repair</h2>
                                <p>Fix it fast, fix it right.</p>
                                Repair:
                                Fix it fast, fix it right.
                                Fix your phone, don't replace.
                                Repair today, connect tomorrow.
                            </div>
                        </div>*/}
                    </div>
                </div>
            </section>
            <section className="super-offer-section">
                <div className="container">
                    <div className="heading d-flex justify-content-between align-items-centr">
                        <h2 className="regular-28 text-uppercase">Super Offers</h2>
                        <button className="button text-only">
                            <span className="regular-16">{`See All >>`} </span>
                        </button>
                    </div>
                    <div className="d-flex offers-view justify-content-between">
                        <div className="one-half full">
                            <img src="/images/supper-offer-1.webp" alt="offer" />
                        </div>
                        <div className="one-half grid">
                            <div className="grid-items">
                                <div className="single-item">
                                    <img src="/images/supper-offer-2.webp" alt="offer" />
                                </div>
                                <div className="single-item">
                                    <img src="/images/supper-offer-3.webp" alt="offer" />
                                </div>
                                <div className="single-item">
                                    <img src="/images/supper-offer-4.webp" alt="offer" />
                                </div>
                                <div className="single-item">
                                    <img src="/images/supper-offer-5.jpeg" alt="offer" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="catergory-slider-section">
                <div className="container section-padding">
                    <div className="heading d-flex justify-content-between align-items-centr pb-3">
                        <h2 className="regular-28 text-uppercase">Top Categories</h2>

                        {/*<ul className="d-flex">
                            <li className="regular-28 active">Buy</li>
                            <li className="regular-28">Sell</li>
                        </ul>*/}
                    </div>
                    <div className="top-categories d-flex">
                        <div className="top-categories-first">
                            <img src="/images/top-cat-1.webp" alt="category" />
                        </div>
                        <div className="top-categories-others">
                            <CategorySlider />
                        </div>
                    </div>
                </div>
            </section>
            <section className="customer-review-section pb-5">
                <div className="container section-padding">
                    <div className="heading d-flex justify-content-between align-items-centr pb-3">
                        <h2 className="regular-28 text-uppercase">Customers’ words</h2>
                    </div>
                    <CustomerReview />
                    <div className="text-center mt-4">
                        Rated{" "}
                        <span className="weight-600">
                            {parseFloat(totalReview?.rating).toFixed(2)}/5
                        </span>{" "}
                        Based on <span className="weight-600">{totalReview?.totalUser}</span>{" "}
                        reviews.
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
