import React from "react";

const RtReturnWarranty = () => {
    return (
        <div className="container section-padding mb-5">
            <h1 className="regular-28 mb-2 text-center">RT Return Policy</h1>
            <div className="intro">
                <h1 className="medium-20 mb-2">How can I get my phone back?</h1>
                <p className="mb-3">
                    If the phone is in the same condition as when you received it, you may return it
                    during the 14-day return window. Anyway, if your phone has a problem, we advise
                    submitting it to our warranty service so that we can genuinely fix the issue.
                </p>
                <p className="mb-3">
                    Please complete the return form at https: rebuildtechno.com if you want to
                    return your phone. After submitting the form, you will be given a free printable
                    Posti return label.
                </p>
                <p className="mb-3">
                    Furthermore, make sure the phone is reset and Find My iPhone is not enabled
                    before returning it. You can get in touch with our customer care if issues
                    arise.
                </p>
            </div>
            <div className="receive-refund">
                <h1 className="medium-20 mt-4 mb-2">
                    I want to give my phone back. Can I receive a refund on my purchase?
                </h1>
                <p className="mb-3">
                    For all of our phones, we provide a 14-day return policy. You have two weeks to
                    test the phone starting from the day you receive it. You can return the phone if
                    you're not fully satisfied with it, and we'll refund your money or cancel the
                    bill. The phone must, however, be in the same outward condition as when you
                    acquired it.
                </p>
                <p className="mb-3">
                    After submitting this form, you will also receive a Posti return sticker
                    through email that you can use to send a free return package.
                </p>
                <p className="mb-3">
                    We offer a 14-day right of return for all our phones. Counting from receiving
                    the phone, you have two weeks to try the phones...
                </p>
            </div>
            <div className="receive-refund">
                <h1 className="medium-20 mt-4 mb-2">
                    What is the optimize time for refunds and when will I receive my money back?
                </h1>
                <p className="mb-3">
                    After receipt of the phone, we handle the return in 1 to 5 business days. Then,
                    we will credit the invoice or the money will be credited to your account. Please
                    be aware that if the Find My iPhone feature is still active on the phone, we
                    will need to wait until it has been switched off to process it, which could take
                    longer.
                </p>
            </div>
            <div className="warranty mt-5">
                <h1 className="regular-28 mb-4 text-center">RT Warranty Policy</h1>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        How much time does warranty service require?
                    </h1>
                    <p className="mb-3">
                        After the service has been received the device, the warranty service window
                        lasts for 4-6 business days. Anyway, we always try to resolve warranty
                        issues quickly.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        In which way can I send my device for warranty service?
                    </h1>
                    <p className="mb-3">
                        By completing the electronic return form, you can return the device to us
                        for repair.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        Is a damaged device covered by the warranty?
                    </h1>
                    <p className="mb-3">
                        Rebuild Techno devices (like as phone's battery, microphone, camera as well
                        as other technical matters are all covered by the guaranteed service).{" "}
                    </p>
                    <p className="mb-3">
                        Although, our warranty services do not cover the damage you have caused to
                        yours.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        How can "Find My Device " be turned off?
                    </h1>
                    <p className="mb-3">
                        Proper guidelines for turning off the function in the iCloud system on a
                        device where enter your correct email address and password. For example,
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        Why should Find My device be turned off?
                    </h1>
                    <p className="mb-3">
                        In the device security location where you can put your Apple ID switch off.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">What is covered by the warranty?</h1>
                    <p className="mb-3">
                        The Rebuild techno warranty covers every technical issue with the device
                        like as battery, microphones, cameras, speakers, and so on. We also promise
                        quicker service to the customers.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">What is the duration of the warranty?</h1>
                    <p className="mb-3">
                        All phones and devices purchased from rebuild techno come with a 12-month
                        warranty from us.
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        I forgot to turn off Find My Device. What should or can I do?
                    </h1>
                    <p className="mb-3">
                        If you forgot to turn off Find My iPhone when you sent us your phone, you
                        can turn it off at icloud.com. Below...
                    </p>
                </div>
                <div>
                    <h1 className="medium-20 mt-4 mb-2">
                        How can I uninstall Locate My iPhone from iCloud.com?
                    </h1>
                    <p className="mb-3">
                        In the phone's settings, under iCloud (settings - top point (see your name)
                        and other things, it is simple to off the Find My iPhone feature.
                    </p>
                    <p class="dark_02 mt-3">
                        <i>Warranty cover by Rebuild Techno</i>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RtReturnWarranty;
