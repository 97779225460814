import React, { useState, useEffect } from "react";
import AddAddress from "./AddAddress";
import EditAddress from "./EditAddress";
import { BASE_URL } from "../../utils/constant";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import Modal from "react-bootstrap/Modal";

const AddressBook = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [address, setAddress] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(
    JSON.parse(localStorage.getItem("selectedShippingAddress"))
  );

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(
    JSON.parse(localStorage.getItem("selectedBillingAddress"))
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const id = userInfo?.id;

  const updateAddresses = () => {
    Http.GET(`${BASE_URL}/user/${id}/client/location`, "", true)
      .then((response) => {
        const data = response.data;
        setBillingAddresses(
          data.filter((item) => item.locationResponse.type === "BILLING")
        );
        setShippingAddresses(
          data.filter((item) => item.locationResponse.type === "SHIPPING")
        );
        setAddress(data);
      })
      .catch((response) => {
        toastError(response.message);
      });
  };

  const getAddress = () => {
    updateAddresses();
  };

  const editAddress = (event, idToEdit) => {
    event.stopPropagation();
    setIdToEdit(idToEdit);
    setShowEdit(true);
  };

  const confirmDelete = (event, locationId) => {
    event.stopPropagation();
    setAddressToDelete(locationId);
    setShowConfirmModal(true);
  };

  const deleteAddress = () => {
    if (!addressToDelete) {
      handleClose();
      return;
    }

    Http.DELETE(`${BASE_URL}/user/client/location/`, "", addressToDelete, true)
      .then((response) => {
        toastSuccess("Deleted");
        getAddress();
        setAddressToDelete(null);
        handleClose();
      })
      .catch((response) => {
        toastError(response.message);
        setAddressToDelete(null);
        handleClose();
      });
  };

  useEffect(() => {
    getAddress();
  }, []);

  const handleClose = () => setShowConfirmModal(false);

  useEffect(() => {
    localStorage.setItem(
      "selectedShippingAddress",
      JSON.stringify(selectedShippingAddress)
    );
  }, [selectedShippingAddress]);

  useEffect(() => {
    localStorage.setItem(
      "selectedBillingAddress",
      JSON.stringify(selectedBillingAddress)
    );
  }, [selectedBillingAddress]);

  return (
    <>
      <div className="address-book">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h2 className="regular-16">Address Book</h2>
          <button className="button text-only" onClick={() => setShowAdd(true)}>
            <p className="regular-16">
              <span className="regular-20 plus">+</span> Add Address
            </p>
          </button>
        </div>
        <div className="both-addresses">
          <div className="address-book-content">
            {billingAddresses &&
              billingAddresses.map((item, index) => (
                <div
                  className={`single-address ${
                    item.locationResponse.id === selectedBillingAddress 
                      ? "active"
                      : ""
                  }`}
                  key={item.id}
                  onClick={() =>
                    setSelectedBillingAddress(item?.locationResponse?.id)
                  }
                >
                  <div className="address-box">
                    <p className="reglar-14 weight-500 mb-1  d-flex justify-content-between">
                      {item?.locationResponse?.type}
                      <div>
                        <span
                          className="green cursor-pointer edit me-1"
                          onClick={(e) =>
                            editAddress(e, item?.locationResponse?.id)
                          }
                        >
                          Edit
                        </span>

                        <span
                          className="green cursor-pointer delete text-danger ms-2"
                          onClick={(e) =>
                            confirmDelete(e, item?.locationResponse?.id)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    </p>
                    <p className="regular-14 mb-1">
                      <span>{item.locationResponse.countryResponse?.name}</span>{" "}
                    </p>
                    <p className="regular-14 mb-1">
                      {item.locationResponse?.city}
                    </p>
                    <p className="regular-14 mb-1">
                      {item.locationResponse?.address},{" "}
                      {item.locationResponse?.state}, {item.locationResponse?.zip}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div className="address-book-content">
            {shippingAddresses &&
              shippingAddresses.map((item, index) => (
                <div
                  className={`single-address ${
                    item.locationResponse?.id === selectedShippingAddress 
                      ? "active"
                      : ""
                  }`}
                  key={item?.id}
                  onClick={() =>
                    setSelectedShippingAddress(item?.locationResponse?.id)
                  }
                >
                  <div className="address-box">
                    <p className="reglar-14 weight-500 mb-1  d-flex justify-content-between">
                      {item?.locationResponse?.type}
                      <div>
                        {" "}
                        <span
                          className="green cursor-pointer edit me-1"
                          onClick={(e) =>
                            editAddress(e, item?.locationResponse?.id)
                          }
                        >
                          Edit
                        </span>
                        <span
                          className="green cursor-pointer delete text-danger ms-2"
                          onClick={(e) =>
                            confirmDelete(e, item?.locationResponse?.id)
                          }
                        >
                          Delete
                        </span>
                      </div>
                    </p>
                    <p className="regular-14 mb-1">
                      <span>{item?.locationResponse?.countryResponse?.name}</span>{" "}
                    </p>
                    <p className="regular-14 mb-1">
                      {item?.locationResponse?.city}
                    </p>
                    <p className="regular-14 mb-1">
                      {item?.locationResponse?.address},{" "}
                      {item?.locationResponse?.state}, {item?.locationResponse?.zip}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <AddAddress
        showAdd={showAdd}
        setShowAdd={setShowAdd}
        updateAddresses={updateAddresses}
      />
      <EditAddress
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        setIdToEdit={setIdToEdit}
        idToEdit={idToEdit}
        updateAddresses={updateAddresses}
      />

      <Modal
        show={showConfirmModal}
        onHide={handleClose}
        className="delete-address-modal"
      >
        <Modal.Header closeButton>
          <h3 className="modal-title">Delete address</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h3 className="regular-16">Are you sure to delete this address?</h3>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <button
              type="submit"
              className="button secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button green ms-3"
              onClick={() => deleteAddress()}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressBook;
