import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from "react-image-magnify";

const ProductDetailsSlider = (props) => {
    let colorWiseDocument = props.colorWiseDocument;
    const settings = {
        customPaging: function (i) {
            let documentResponse = colorWiseDocument[i]?.documentResponse;
            return (
                <a>
                    <img src={documentResponse?.docUrl} />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="product-details-slider">
            <Slider {...settings}>
                {colorWiseDocument &&
                    colorWiseDocument.length > 0 &&
                    colorWiseDocument?.map((item) => (
                        <div key={"slider-" + item.id}>
                            {/* <img
                                src={item?.documentResponse?.docUrl}
                                alt="product"
                                className="large-product-image"
                            /> */}
                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: "Wristwatch by Ted Baker London",
                                        isFluidWidth: true,
                                        src: item?.documentResponse?.docUrl,
                                    },
                                    largeImage: {
                                        src: item?.documentResponse?.docUrl,
                                        width: 1000,
                                        height: 800,
                                    },
                                    enlargedImageContainerStyle: {
                                        zIndex: "1500",
                                    },
                                    enlargedImageContainerDimensions: {
                                        width: "100%",
                                        height: "100%",
                                    },
                                    enlargedImagePosition: "over",
                                    imageClassName: "smallImage",
                                    enlargedImageClassName: "largeImage",
                                }}
                            />
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default ProductDetailsSlider;
