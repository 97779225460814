import axios from "axios";
import { BASE_FRONT_END_URL, BASE_URL, HTTP_STATUS } from "./constant";

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

export const routes = {
    // Users api
    userInfo: `${BASE_URL}/user/me`, // get
    updateProfile: `${BASE_URL}/user/update-basic-info`, //post
    updateProPic: `${BASE_URL}/user/update-profile-picture`, //post
    updatePassword: `${BASE_URL}/user/change-password`, //post
    createAdmin: `${BASE_URL}/user/create-admin`, //post
    editAdmin: `${BASE_URL}/user/admin/`, //post
    getAdminInfo: `${BASE_URL}/user`, // get
    adminUserTypeList: `${BASE_URL}/user/admin-user-type`, // get
    adminList: `${BASE_URL}/user/all-admin`, // get
    resetPassword: `${BASE_URL}/user/reset-password/`, //put

    // location api
    getCountryList: `${BASE_URL}/api/location/country`, // get

    //brand api
    crateBrand: `${BASE_URL}/api/brands`, //post
    brandList: `${BASE_URL}/api/brands/allWithLogo`, // get
    getBrandInfo: `${BASE_URL}/api/brands/`, // get
    editBrand: `${BASE_URL}/api/brands`, // put

    //category api
    createCategory: `${BASE_URL}/api/categories`, //post
    categoryList: `${BASE_URL}/api/categories`, //get
    getCategoryInfo: `${BASE_URL}/api/categories/`, //get
    deleteCategory: `${BASE_URL}/api/categories/`, //delete
    editCategory: `${BASE_URL}/api/categories/`, //put

    //product api
    createProduct: `${BASE_URL}/api/products`, //post
    productList: `${BASE_URL}/api/products`, //get
    productBySlug: `${BASE_URL}/api/products/slug/`, //get
    exclusiveProductList: `${BASE_URL}/api/products/exclusive`, //get
    getCategoryProducts: `${BASE_URL}/api/products/client`, //get
    productQuestionList: `${BASE_URL}/api/product-question/product/`, //get
    addProductQuestionAnswer: `${BASE_URL}/api/product-question/answer`, //post
    productQuestionAnswerList: `${BASE_URL}/api/product-question/answer/cart/`, //Get
    productDropdownList: `${BASE_URL}/api/products/client/dropdown`, //Get

    // Athentication api
    login: `${BASE_URL}/auth/login`, // POST
    signup: `${BASE_URL}/auth/signup`, // POST
    createClient: `${BASE_URL}/user/create-client`,
    forgetPassword: `${BASE_URL}/user/request-forgot-password`, // POST
    completeForgotPassword: `${BASE_URL}/user/complete-forgot-password`, // POST

    //cart api
    checkCartSession: `${BASE_URL}/api/cart/my-session`, //get
    getCartItems: `${BASE_URL}/api/cart/items/session/`, //get
    addToCart: `${BASE_URL}/api/cart/items`, //post
    updateCart: `${BASE_URL}/api/cart/items/`, //put

    // order api
    placeOrder: `${BASE_URL}/api/order/place`, //post
    myOrder: `${BASE_URL}/api/order/my`, //post
    getOrderData: `${BASE_URL}/api/order/`, //get

    // Faq api
    faqList: `${BASE_URL}/api/faq`, // POST

    // rating api
    getReviewList: `${BASE_URL}/api/customer-rating`, // Get
    getTotalReview: `${BASE_URL}/api/customer-rating/total`, // Get
    addReview: `${BASE_URL}/api/customer-rating`, // POST

    // add doc api
    docAdd: `${BASE_URL}/doc/add`, // POST

    //
    paymentTest: `${BASE_URL}/api/payment/initiate`, // POST
};

// Axios request interceptor
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    config.headers.Authorization = token ? token : "";
    config.headers.unameid = userInfo?.id || "";
    config.headers.unamefull = userInfo?.name || "";
    return config;
});

// Axios response interceptor
axios.interceptors.response.use(
    (response) => {
        if (response.config.url.includes("logout")) {
            delete axios.defaults.headers.common["Authorization"];
        }
        return response;
    },
    (error) => {
        const originalRequest = error.config;

        // If the response status is 401 (Unauthorized) and the original request didn't already try to refresh the token
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Call the refresh token API to get a new token
            return axios
                .post(BASE_URL + "/auth/refresh", {
                    refreshToken: localStorage.getItem("refreshToken"),
                })
                .then((response) => {
                    // If the token is refreshed successfully, update the stored token and retry the original request
                    const newToken = response.data.accessToken;
                    localStorage.setItem("token", newToken);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
                    originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                    return axios(originalRequest);
                })
                .catch((error) => {
                    // Handle refresh token failure (e.g., redirect to login page)
                    console.error("Failed to refresh token:", error);
                });
        }
        return Promise.reject(error);
    }
);

const encodeQueryData = (data) => {
    let ret = [],
        temp;
    for (let i in data) {
        temp = data[i];
        if (temp !== "" && temp !== null) {
            ret.push(encodeURIComponent(i) + "=" + encodeURIComponent(temp));
        }
    }
    return ret.length ? "?" + ret.join("&") : "";
};

const Http = {
    GET: (key, params = "", isRestFormat = false) => {
        // updateTokenInHeader();
        params = typeof params === "object" ? encodeQueryData(params) : params;
        if (isRestFormat) {
            return axios.get(key + params, headers);
        } else {
            return axios.get(routes[key] + params, headers);
        }
    },
    GET_WITH_ID_PARAM: (key, params = "", id = "") => {
        params = typeof params === "object" ? encodeQueryData(params) : params;
        return axios.get(routes[key] + id + params, headers);
    },
    GET_FILE_DATA: (key, params = "", isRestFormat = false) => {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Content-Disposition": "attachment",
            },
            responseType: "blob",
        };
        params = typeof params === "object" ? encodeQueryData(params) : params;
        if (isRestFormat) {
            return axios.get(key + params, config);
        } else {
            return axios.get(routes[key] + params, config);
        }
    },
    POST: (key, params, id = "", isRestFormat = false) => {
        if (isRestFormat) {
            return axios.post(key + id, params, { headers: headers });
        } else {
            return axios.post(routes[key] + id, params, { headers: headers });
        }
    },
    POST_TEXT_BODY: (key, params, id = "") => {
        return axios.post(routes[key] + id, params, {
            "Content-Type": "text/html",
            Accept: "application/json",
        });
    },
    PUT: (key, params, id = "", isRestFormat = false) => {
        if (isRestFormat) {
            return axios.put(key + id, params, { headers: headers });
        } else {
            return axios.put(routes[key] + id, params, { headers: headers });
        }
    },
    PATCH: (key, params, id = "", isRestFormat = false) => {
        if (isRestFormat) {
            return axios.patch(key + id, params, { headers: headers });
        } else {
            return axios.patch(routes[key] + id, params, { headers: headers });
        }
    },
    DELETE: (key, data, id = "", isRestFormat = false) => {
        if (isRestFormat) {
            return axios.delete(key + id, { data, headers });
        } else {
            return axios.delete(routes[key] + id, { data, headers });
        }
    },
    DELETE_WITH_BODY: (key, body, params = "") => {
        // updateTokenInHeader();
        params = typeof params === "object" ? encodeQueryData(params) : params;
        return axios.delete(routes[key] + params, { headers, data: body });
    },
    UPLOAD: (key, { name, file }) => {
        // updateTokenInHeader();
        const formData = new FormData();
        formData.append(name, file);

        return axios.post(routes[key], formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    UPLOAD_MULTIPLE_FILE: (key, files = [], id, responseType) => {
        let formData = new FormData();
        files.forEach((file, i) => {
            formData.append(`file`, file);
        });
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Content-Disposition": "attachment",
            },
        };
        if (!responseType) {
            config["responseType"] = "blob";
        }
        return axios.post(routes[key] + id, formData);
    },
    DOWNLOAD: (key, params = "", id, progressCallback) => {
        // Only POST is supported
        // updateTokenInHeader();
        return axios.post(routes[key] + id, params, {
            headers: {
                "Content-Disposition": "attachment",
            },
            responseType: "blob",
            // onDownloadProgress: (progressEvent) => {
            // 	progressCallback(progressEvent);
            // }
        });
    },
    UPLOAD_WITH_PROGRESS: (key, params, id = "", progressCallback) => {
        // if(key!=='signup' || key!=='login'){
        // //console.log("token header called",key);
        // updateTokenInHeader();
        // }
        // //console.log("from post",routes[key] + id, params)
        return axios.post(routes[key] + id, params, {
            headers,
            onUploadProgress: (data) => {
                //Set the progress value to show the progress bar
                progressCallback(data, params);
            },
        });
    },
};
export default Http;
