const ProductTechnicalDetails = ( props ) => {
    let product = props.product;

    return (
        <>
            <div className="technical-details mt-5">
                <h3 className="regular-20 mb-2">Technical details</h3>
                <table className="table table-striped">
                    <tbody>
                    {product?.technicalTagResponseList &&
                        product?.technicalTagResponseList.map((item) => (
                            <tr key={item.id}>
                                <td>
                                    <h3 className="regular-16">{item?.key}</h3>
                                </td>
                                <td>
                                    <p className="regular-14">{item?.text}</p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );

};

export default ProductTechnicalDetails;