import ProductDetailsSlider from "../slider/ProductDetailsSlider";
import {useEffect, useState} from "react";

const ProductColorWiseDocument = ( props ) => {
    const [uniqueColorWisePriceList, setUniqueColorWisePriceList] = useState([]);
    const [selectedColorDocuments, setSelectedColorDocuments] = useState([]);
    const [selectedColorId, setSelectedColorId] = useState(null);
    let product = props.product;

    const uniqueColorWisePriceGenerate = () => {
        if( product?.documentColorMapResponses === undefined )
            return;
        const uniqueColorWisePrice = [];
        const seenColors = new Set();

        for (const colorWisePrice of product?.documentColorMapResponses) {
            const colorId = colorWisePrice.colorResponse.id;
            if (!seenColors.has(colorId)) {
                seenColors.add(colorId);
                uniqueColorWisePrice.push(colorWisePrice);
            }
        }

        setUniqueColorWisePriceList(uniqueColorWisePrice);
    }

    const setDocumentColors = (colorId) => {
        let colorDocumentList = product?.documentColorMapResponses?.filter(
            (item) => item.colorResponse.id === colorId
        );
        setSelectedColorId(colorId);
        setSelectedColorDocuments(colorDocumentList);

    };

    useEffect(() => {
        if( product !== null ){
            let fetureImageDocument = {
                documentResponse: product.featureImageResponse,
            };
            setSelectedColorDocuments([fetureImageDocument]);
        }
        uniqueColorWisePriceGenerate(  );
    }, [ product?.documentColorMapResponses ]);

    return (
        <>
           <div>
               <ProductDetailsSlider colorWiseDocument={selectedColorDocuments} />

               <div className="available-colors mt-4">
                   <h3 className="regular-16 mb-1"></h3>
                   <div className="colors d-flex">
                       {product &&
                           product?.documentColorMapResponses &&
                           uniqueColorWisePriceList?.map((item) => (
                               <div
                                   className={`${
                                       item?.colorResponse?.id === selectedColorId
                                           ? "single-color text-center active"
                                           : "single-color text-center"
                                   }`}
                                   key={"document_color_" + item.id}
                                   onClick={() =>
                                       setDocumentColors(item?.colorResponse?.id)
                                   }
                               >
                                                <span
                                                    className="color-icon"
                                                    style={{
                                                        backgroundColor: `${item?.colorResponse?.hexCode}`,
                                                    }}
                                                >
                                                    {item.colorResponse.hexcode}
                                                </span>
                                   <p>
                                       {item.colorResponse.name} : {item.price}
                                   </p>
                               </div>
                           ))}
                   </div>
               </div>
           </div>
        </>
    );
};
export default ProductColorWiseDocument;