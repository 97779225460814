import React, { useEffect, useState } from "react";
import ProductDetailsSlider from "../slider/ProductDetailsSlider";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Http from "../../utils/Http";
import ProductSlider from "../slider/ProductSlider";
import addToCartCount from "../../actions/action";
import { toastError, toastSuccess } from "../../utils/Toast";
import { CART_SESSION_NAME, HOURLY_REPAIRE_RATE } from "../../utils/constant";
import Select from "react-select";
import ProductTechnicalDetails from "./ProductTechnicalDetails";

const ProductDetails = () => {
  const [product, setProduct] = useState({});
  const [selectedColorDocuments, setSelectedColorDocuments] = useState({});
  const [uniqueColorWisePriceList, setUniqueColorWisePriceList] = useState([]);
  const [selectedColorId, setSelectedColorId] = useState(null);
  const [price, setPrice] = useState(null);
  const [productQuestionList, setProductQuestionList] = useState([]);
  const [originalProductQuestionList, setOriginalProductQuestionList] = useState([]);
  const [repairTypeQuestionDropdown, setRepairTypeQuestionDropdown] = useState([]);
  const [selectedRepairQuestion, setSelectedRepairQuestion] = useState({});
  const [cartResponse, setCartResponse] = useState({});
  const [capacityTagList, setCapacityTagList] = useState({});
  const [batteryTagList, setBatteryTagList] = useState({});
  const [selectedTagList, setSelectedTagList] = useState([]);

  const [questionSearchTerm, setQuestionSearchTerm] = useState("");
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCartSession = () => {
    const session = localStorage.getItem(CART_SESSION_NAME);
    return session === undefined ? "" : session;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 5;
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = productQuestionList.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  const handleNextPage = () => {
    console.log("handleNextPage");
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    console.log("handlePrevPage");
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const getProduct = () => {
    console.log("getProduct");
    Http.GET("productBySlug", slug)
      .then((response) => {
        let data = response.data;
        setProduct( data );
        setPrice( data?.price );
        let fetureImageDocument = {
          documentResponse: data.featureImageResponse,
        };
        setCapacityTagList(data.capacityTagResponseList);
        setBatteryTagList(data.batteryTagResponseList);
        setSelectedColorDocuments([fetureImageDocument]);
        if (data.categoryType === "SELL" || data.categoryType === "REPAIR") {
          getProductQuestionList(data.id);
        }

        const uniqueColorWisePrice = [];
        const seenColors = new Set();

        for (const colorWisePrice of data.documentColorMapResponses) {
          const colorId = colorWisePrice.colorResponse.id;
          if (!seenColors.has(colorId)) {
            seenColors.add(colorId);
            uniqueColorWisePrice.push(colorWisePrice);
          }
        }

        setUniqueColorWisePriceList(uniqueColorWisePrice);
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  useEffect(() => {
    console.log("document wise color")
    if (uniqueColorWisePriceList.length > 0) {
      setDocumentColors(uniqueColorWisePriceList[0].colorResponse.id);
    }
  }, [uniqueColorWisePriceList]);

  const setDocumentColors = (colorId) => {
    console.log("set document colors");
    let colorDocumentList = product?.documentColorMapResponses?.filter(
      (item) => item.colorResponse.id === colorId
    );
    setSelectedColorId(colorId);
    setSelectedColorDocuments(colorDocumentList);

    generatePrice(selectedTagList, colorId);

    /*if (colorDocumentList && colorDocumentList.length > 0) {
            setPrice( colorDocumentList[0].price );
        } else {
            setPrice( product.price );
        }*/
  };

  const addToCartApiCall = async () => {
    console.log("addTocartAPiCAll");
    let tagIds = selectedTagList.map((item) => item.id);
    const formData = {
      productId: product.id,
      colorId: selectedColorId,
      quantity: 1,
      price: product.price,
      session: getCartSession(),
      tagIds: tagIds,
    };
    const { data } = await Http.POST("addToCart", formData);
    return data;
  };

  const addToCart = async (buyType) => {
    console.log("addtocart");
    addToCartApiCall()
      .then(async (data) => {
        await setCartResponse(data);
        localStorage.setItem(CART_SESSION_NAME, data.session);
        dispatch(addToCartCount({ count: 1 }));
        toastSuccess("Product added on cart");
        if (buyType === "buyNow") {
          navigate("/checkout");
        }
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.message) {
          toastError(response.data.message);
        } else {
          toastError("Request wasn't successful");
        }
      });
  };

  const getProductQuestionList = (id) => {
    console.log("getProductQuestions");
    Http.GET("productQuestionList", id)
      .then(({ data }) => {
        data = data.map((item) => {
          item["answer"] = { selectedOptions: "" };
          return item;
        });
        setProductQuestionList(data);
        setOriginalProductQuestionList([...data]);
        let repairTypeQuestionDropdown = data.map((item) => {
          return { value: item.id, label: item.title };
        });
        setRepairTypeQuestionDropdown(repairTypeQuestionDropdown);
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleChangeRepairTypeQuestionDropdown = (selectedItem) => {
    console.log( "handleChangeRepairTypeQuestionDropdown" );
    setSelectedRepairQuestion({ value: "", label: "" });
    for (let i = 0; i < productQuestionList.length; i++) {
      if (selectedItem.value === productQuestionList[i].id) {
        selectAnswerQuestion(
          i,
          productQuestionList[i]?.optionResponseList[0].id
        );
      }
    }
  };

  const addAnswer = async (questionId, optionId, cartResponse) => {
    console.log("addAnswer");
    const formData = {
      cartId: cartResponse.id,
      session: cartResponse.session,
      questionId: questionId,
      selectedOptionIds: [optionId],
    };
    await Http.POST("addProductQuestionAnswer", formData)
      .then(({ data }) => {
        setPrice(data.price);
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.message) {
          toastError(response.data.message);
        } else {
          toastError("Request wasn't successful");
        }
      });
  };

  const selectAnswerQuestion = async (index, optionId) => {
    console.log("selectedAnswer");
    let questionList = currentQuestions;
    if (product?.categoryType === "REPAIR") questionList = productQuestionList;
    let questionId = questionList[index].id;
    let tempProductQuestionList = productQuestionList.map((item) => {
      if (item.id === questionId) item.answer = { selectedOptions: optionId };
      return item;
    });
    setProductQuestionList(tempProductQuestionList);
    // setOriginalProductQuestionList( [...tempProductQuestionList] );

    if (cartResponse.id === undefined) {
      addToCartApiCall()
        .then(async (data) => {
          await setCartResponse(data);
          localStorage.setItem(CART_SESSION_NAME, data.session);
          dispatch(addToCartCount({ count: 1 }));
          addAnswer(questionId, optionId, data);
          // toastSuccess("Product added on cart");
        })
        .catch(({ response }) => {
          if (response && response.data && response.data.message) {
            toastError(response.data.message);
          } else {
            toastError("Request wasn't successful");
          }
        });
    } else addAnswer(questionId, optionId, cartResponse);
  };

  const generatePrice = ( temporarySelectedTags = selectedTagList, colorId = selectedColorId ) => {
    console.log("generate price");
    let tempPrice = product.price;

    let colorDocumentList = product?.documentColorMapResponses?.filter(
      (item) => item.colorResponse.id === colorId
    );

    if (colorDocumentList && colorDocumentList.length > 0) {
      tempPrice = colorDocumentList[0].price;
    }

    let finalPrice = tempPrice;

    for (const element of temporarySelectedTags) {
      if (element.amountType === "FLAT_DISCOUNT") {
        finalPrice += element.amount;
      } else {
        finalPrice += (tempPrice * element.amount) / 100;
      }
      if (product.categoryType === "REPAIR" && element?.requireMinute > 0) {
        finalPrice = HOURLY_REPAIRE_RATE * (element.requireMinute / 60);
      }
    }
    setPrice(finalPrice);
  };

  const changeTagSelection = (tag) => {
    console.log("change tag selection");
    let temporarySelectedTags = [...selectedTagList];
    temporarySelectedTags = temporarySelectedTags.filter(
      (item) => item.type !== tag.type
    );
    temporarySelectedTags.push(tag);
    setSelectedTagList(temporarySelectedTags);

    generatePrice(temporarySelectedTags, selectedColorId);
  };

  const getSelectedQuestions = () => {
    console.log("get selected questions");
    let selectedItem = productQuestionList
      .filter((item) => item.answer?.selectedOptions !== "");
      //.map((item) => item.title);
    return (
      <>
        {selectedItem.map((item, index) => (
          <p key={item.id} className="mb-1">
            {index + 1}. {item.title}{" "}
          </p>
        ))}
      </>
    );
  };

  const checkTagSelected = (tag) => {
    console.log( "checkTagSelected");
    let temporarySelectedTags = [...selectedTagList];
    const selectedTag = temporarySelectedTags.filter(
      (item) => item.id === tag.id
    );
    return selectedTag.length > 0 ? "active" : "";
  };

  const gotoCheckOut = () => {
    navigate("/cart");
  };

/*  useEffect(() => {
    if (product?.price) {
      setPrice(product.price);
    }
  }, [product?.price]);*/

  useEffect(() => {
    getProduct();
  }, [slug]);

  useEffect(() => {
    console.log("question search term");
    let tempQuestions = [...originalProductQuestionList];
    tempQuestions = tempQuestions.filter((item) => {
      return item?.title
        .toLowerCase()
        .includes(questionSearchTerm.toLowerCase());
    });
    setProductQuestionList(tempQuestions);
    setCurrentPage(1);
  }, [questionSearchTerm]);

  return (
      // <div></div>
    <div>
      <div className="container section-padding">
        <div className="product-details-info">
          <div className="images">
            <div className="product-image-slider">
              <ProductDetailsSlider
                colorWiseDocument={selectedColorDocuments}
              />
            </div>
          </div>
          {product?.categoryType === "BUY" ? (
            <div className="information">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <h3 className="regular-20 weight-700">{product?.name}</h3>
                  <p className="regular-16">
                    {selectedColorDocuments?.[0]?.colorResponse &&
                      selectedColorDocuments?.[0]?.colorResponse?.name}

                    {selectedTagList &&
                      selectedTagList.map((item) => (
                        <span key={item.id}>
                          {" "}
                          | {item?.text}
                        </span>
                      ))}
                  </p>
                </div>
                <div className="text-end">
                  <h3 className="price weight-600 green mb-0">
                    {price
                      ? (
                          price -
                          ((product?.discountPercentage || 0) / 100) * price
                        ).toFixed(1)
                      : 0}
                    {product?.discountPercentage && (
                      <del>({price?.toFixed(1)})</del>
                    )}
                  </h3>
                  <p className="fs-6">incl. vat</p>
                </div>
              </div>
              {capacityTagList.length > 0 && (
                <div className="varient-row mb-2">
                  <h3 className="regular-16 mb-1">Capacity:</h3>
                  <div className="varients">
                    {capacityTagList.map((item) => (
                      <div
                        className={`single-varient mb-2 ${checkTagSelected(item)}`}
                        onClick={() => changeTagSelection(item)}
                        key={ item.id}
                      >
                        <h4>{item?.text}</h4>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {product && product.condition && (
                <div className="varient-row mb-2">
                  <h3 className="regular-16 mb-1">Condition:</h3>
                  <div className="varients">
                    <div className="single-varient active">
                      <h4>{product.condition}</h4>
                    </div>
                  </div>
                </div>
              )}
              {batteryTagList.length > 0 && (
                <div className="varient-row mb-2">
                  <h3 className="regular-16 mb-1">Battery:</h3>
                  <div className="varients">
                    {batteryTagList &&
                      batteryTagList.map((item) => (
                        <div
                          className={`single-varient mb-2 ${checkTagSelected(item) }`}
                          onClick={() => changeTagSelection(item)}
                          key={ item.id}
                        >
                          <h4>{item?.text}</h4>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              <div className="available-colors mt-4">

                <div className="colors d-flex">
                  {uniqueColorWisePriceList &&
                    uniqueColorWisePriceList.map((item) => (
                      <div
                        className={`${
                          item?.colorResponse?.id === selectedColorId
                            ? "single-color text-center active"
                            : "single-color text-center"
                        }`}
                        key={item.id}
                        onClick={() =>
                          setDocumentColors(item.colorResponse.id)
                        }
                      >
                        <span
                          className="color-icon"
                          style={{
                            backgroundColor: `${item?.colorResponse?.hexCode}`,
                          }}
                        >
                          {item.colorResponse.hexcode}
                        </span>
                        <p>
                          {item.colorResponse.name} : {item.price}
                        </p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="available-quantity mt-3">
                <h3 className="regular-16">
                  Availability:{" "}
                  {product?.availableQty > 0 ? (
                    <span className="green">
                      In stock ({product?.availableQty})
                    </span>
                  ) : (
                    <span className="text-danger">Out of stock</span>
                  )}
                </h3>
              </div>

              {product?.grade && (
                <div className="grade-info mt-3">
                  <h3 className="regular-16">
                    Grade:{" "}
                    <span
                      className="grade"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        product?.grade === "A"
                          ? "Excellent (Grade A): the best cosmetic quality for a refurbished item. The product’s appearance is like new, and there are no scratches on the screen."
                          : product?.grade === "B"
                          ? "Good (Grade B): the devices present obvious signs of use with light signs of wear but no scratches on the screen."
                          : "Fair (Grade C): there will be visible scratches and moderate cosmetic defects. It still complies with Back Market’s quality standards."
                      }
                    >
                      {product?.grade}
                    </span>
                  </h3>
                </div>
              )}

              <div className="extra-info my-4">
                <h3 className="regular-16">Included in the box</h3>
                <p className="regular-14">{product?.boxContain}</p>
              </div>
              {product?.availableQty > 0 && (
                <>
                  <button
                    className="button green me-2"
                    onClick={() => addToCart("AddToCart")}
                  >
                    ADD TO CART
                  </button>
                  <button
                    className="button green"
                    onClick={() => addToCart("buyNow")}
                  >
                    BUY NOW
                  </button>
                </>
              )}
            </div>
          ) : (
            <div className="information sell-type">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <h3 className="regular-20 weight-700">{product?.name}</h3>
                </div>
                <div className="text-end">
                  <h3 className="price weight-600 green mb-0">
                    Estimated{" "}
                    {price
                      ? (
                          price -
                          ((product?.discountPercentage || 0) / 100) * price
                        ).toFixed(1)
                      : 0}
                    {product?.discountPercentage && (
                      <del>({price?.toFixed(2)})</del>
                    )}
                  </h3>
                  <p className="fs-6">incl. vat</p>
                </div>
              </div>
              {product?.categoryType === "SELL" && (
                <h3 className="regular-20 mb-3">
                  To get a better pricing please answer the questions:
                </h3>
              )}
              {product?.categoryType === "SELL" && (
                <div className="d-flex search-box align-items-center category-product-search me-3 mb-3">
                  <input
                    type="text"
                    className="form-control common-list-search"
                    placeholder="Search..."
                    aria-label="Search"
                    value={questionSearchTerm}
                    onChange={(e) => setQuestionSearchTerm(e.target.value)}
                  />
                  <img
                    src="/icons/search.svg"
                    alt="search"
                    className="search"
                  />
                </div>
              )}

              {product?.categoryType === "REPAIR" && (
                <div>
                  {getSelectedQuestions()}
                  <div className="mt-2">
                    <Select
                      value={selectedRepairQuestion}
                      onChange={(event) =>
                        handleChangeRepairTypeQuestionDropdown(event)
                      }
                      options={repairTypeQuestionDropdown}
                      placeholder="Select question"
                    />
                  </div>
                </div>
              )}

              {product?.categoryType === "SELL" && (
                <div className="all-questions">
                  {currentQuestions.map((item, index) => (
                    <div
                      className="varient-row mb-2"
                      key={item.id}
                    >
                      <h3 className="regular-16 mb-2 title">{item?.title}</h3>
                      <div className="varients">
                        {item?.optionResponseList &&
                          item.optionResponseList.map((option, optionIndex) => (
                            // please add "active" class with single varient on select

                            <div
                              className={`single-varient ${
                                option.id === item?.answer?.selectedOptions
                                  ? "active"
                                  : ""
                              }`}
                              key={option.id}
                              onClick={() =>
                                selectAnswerQuestion(index, option.id)
                              }
                            >
                              <h4>{option.title}</h4>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {product?.categoryType === "SELL" && (
                <div className="pagination mt-3 ms-1">
                  {currentPage > 1 && (
                    <button
                      className="button green pagination-button me-2"
                      onClick={handlePrevPage}
                      //disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  )}
                  {indexOfLastQuestion <= productQuestionList.length && (
                    <button
                      className="button green pagination-button"
                      onClick={handleNextPage}
                      ///disabled={indexOfLastQuestion >= productQuestionList.length}
                    >
                      Next
                    </button>
                  )}

                  <button
                    className="button green submit ms-2"
                    onClick={gotoCheckOut}
                  >
                    Submit
                  </button>
                </div>
              )}

              {product?.categoryType === "REPAIR" && (
                <div className="pagination mt-3 ms-1">
                  <button
                    className="button green submit ms-2"
                    onClick={gotoCheckOut}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="delivery-info">
        <div className="container section-padding">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src="/icons/truck.svg" alt="delivery" />
              <h3 className="regular-14 my-1">Quick delivery and pickups</h3>
              <p className="regular-14">
                You will receive your phone <br /> within 1-3 business days
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img src="/icons/lock.svg" alt="delivery" />
              <h3 className="regular-14 my-1">Secure payments</h3>
              <p className="regular-14">
                We only use the most <br /> trustworthy local payment <br />{" "}
                providers
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img src="/icons/shield.svg" alt="delivery" />
              <h3 className="regular-14 my-1">Warranty and returns</h3>
              <p className="regular-14">
                12-month Rebuildtechno warranty <br />
                and 14-day free returns
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="feature-info">
        <div className="container section-padding">
          <div className="text-center">
            <h3 className="regular-28">{product?.name} features</h3>
            <p className="regular-14">{product?.description}</p>
          </div>
          <ProductTechnicalDetails product={product}></ProductTechnicalDetails>
        </div>
      </div>
      <section className="best-selling-slider-section">
        <div className="container section-padding">
          <div className="related-products">
            <div className="category-products pt-4 pb-4 border-top-solid">
              <h2 className="regular-28 text-uppercase mb-3 ">
                You may also like
              </h2>
              <ProductSlider />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetails;
