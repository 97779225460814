const initialState = {
  cart: [],
  count: 0,
  cartElementcount: 0,
};

const updateCart = (state = initialState, action) => {
  switch (action.type) {
    case "ADDTOCART":
      state.cartElementcount += action.payload.count;
      return {
        ...state,
      };
    case "EMPTYCARTCOUNT":
      state.cartElementcount = 0;
      return {
        ...state,
      };
    case "REMOVEONE": {
      state.cartElementcount += action.payload.count;
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default updateCart;
