import React, { useState, useEffect } from "react";
import Http from "../../utils/Http";
import { toastError, toastSuccess } from "../../utils/Toast";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

const VerifyEmail = () => {
    const [password, setPassword] = useState("");
    const [retypePassword, setretypePassword] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = () => {
        let token = searchParams.get("token");
        let body = {
            token: token,
            password: password,
            retypePassword: retypePassword,
        };
        Http.POST("completeForgotPassword", body)
            .then(({ data }) => {
                toastSuccess("Password successfully reset");
                navigate("/login");
            })
            .catch(({ response }) => {
                handleErrorResponse(response);
            });
    };

    function handleErrorResponse(response) {
        let message = "Something went wrong!"; // Default message
        try {
            // Attempt to parse the response data as JSON
            //let data = JSON.parse(response.data.message);
            let data = response.data.message;
            console.log(data);
            if (data?.length > 0 && data[0]?.defaultMessage != undefined) {
                message = data[0]?.defaultMessage;
            } else if (data) {
                message = data;
            }
        } catch (error) {
            // If parsing as JSON fails, use the default message
        }
        toastError(message);
    }

    return (
        <section className="register-section login">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-12 px-0 login-info-container">
                        <div className="card text-black border-0">
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="login-thambnail">
                                        <img src="/images/login.jpg" alt="login" />
                                    </div>
                                    <div className="form-info">
                                        <p className="text-center d-flex align-items-center justify-content-between h2 fw-bold mx-1 mx-md-4 mt-4">
                                            <span>Reset</span>
                                            <img
                                                src="/icons/rebuild-logo.png"
                                                alt="logo"
                                                className="logo"
                                            />
                                        </p>

                                        <form className="form-list mx-1 mx-md-4">
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="form3Example4c"
                                                    >
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        id="form3Example4c"
                                                        className="form-control"
                                                        name="password"
                                                        onChange={(e) =>
                                                            setPassword(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label
                                                        className="form-label mb-0"
                                                        htmlFor="form3Example4cd"
                                                    >
                                                        Repeat your password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        id="form3Example4cd"
                                                        className="form-control"
                                                        name="retypePassword"
                                                        onChange={(e) =>
                                                            setretypePassword(e.target.value)
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                <button
                                                    type="button"
                                                    className="button green"
                                                    onClick={handleSubmit}
                                                >
                                                    Reset
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VerifyEmail;
