import Select from "react-select";
import React, { useEffect, useState } from "react";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import { BASE_URL } from "../../utils/constant";
import ProductColorWiseDocument from "./ProductColorWiseDocument";
import ProductTechnicalDetails from "./ProductTechnicalDetails";

const ProductCompare = () => {
  const [firstProduct, setFirstProduct] = useState({});
  const [secondProduct, setSecondProduct] = useState({});
  const [firstProductDropdown, setFirstProductDropdown] = useState([]);
  const [secondProductDropdown, setSecondProductDropdown] = useState([]);

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const getProductDropdown = (productNumber) => {
    let param = "?category_type=BUY&page=0&size=100&isShowCompare=true";
    Http.GET("productDropdownList", param)
      .then((response) => {
        let data = response.data.data;
        console.log(data);
        if (productNumber === 1) {
          setFirstProductDropdown(data);
        } else if (productNumber === 2) {
          setSecondProductDropdown(data);
        }
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  const getProduct = (slug, productNumber) => {
    console.log(slug);
    Http.GET("productBySlug", slug)
      .then((response) => {
        let data = response.data;
        if (productNumber === 1) {
          setFirstProduct(data);
        } else if (productNumber === 2) {
          setSecondProduct(data);
        }
      })
      .catch((response) => {
        toastError("Something went wrong!");
      });
  };

  useEffect(() => {
    getProductDropdown(1);
  }, []);

  return (
    <>
      <div className="container section-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1 class="medium-20 mb-3">
                Please select the products you want to compare.
              </h1>
            </div>
            <div className="col-md-6 pe-4">
              <Select
                // value={ selectedRepairQuestion }
                onChange={(event) => getProduct(event.value, 1)}
                options={firstProductDropdown}
                placeholder="Select first product"
              />

              {!isEmptyObject(firstProduct) && (
                <div className="product-image-color-info mt-3">
                  <h3 className="regular-16 weight-500 mb-2">
                    {firstProduct?.name}
                  </h3>
                  <ProductColorWiseDocument product={firstProduct} />
                  <div className="product-technical-details">
                    <ProductTechnicalDetails product={firstProduct} />
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 ps-4">
              <Select
                // value={ selectedRepairQuestion }
                onChange={(event) => getProduct(event.value, 2)}
                options={firstProductDropdown}
                placeholder="Select second product"
              />
              {!isEmptyObject(secondProduct) && (
                <div className="product-image-color-info mt-3">
                  <h3 className="regular-16 weight-500 mb-2">
                    {secondProduct?.name}
                  </h3>
                  <ProductColorWiseDocument product={secondProduct} />
                  <div className="product-technical-details">
                    <ProductTechnicalDetails product={secondProduct} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCompare;
