import React, { useEffect } from "react";

const GoogleTranslate = ( {children}) => {
    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: "en,fi,sv",
            },
            "google_translate_element"
        );
    };

    return <div></div>;
};

export default GoogleTranslate;
