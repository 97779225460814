import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CloseButtonSuccess = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src="/icons/close.svg" style={{ height: 24, width: 24 }} />
    </div>
);

const CloseButtonInfo = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <img src="/icons/close.svg" style={{ height: 24, width: 24 }} />
    </div>
);

const CloseButtonError = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
        <img src="/icons/close.svg" style={{ height: 24, width: 24 }} />
    </div>
);

const CloseButtonWarning = ({ closeToast }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
        <img src="/icons/close.svg" style={{ height: 24, width: 24 }} />
    </div>
);

export const toastSuccess = (msg, position) => {
    const Foo = () => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span className="toast-marker toast-success"></span>
            <img
                src="/icons/toast-success.svg"
                className="toast-icon"
                style={{ height: 20, width: 27.6 }}
                alt=""
            />
            <p className="toast-text" style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                {msg}
            </p>
        </div>
    );
    toast(<Foo />, {
        position: "bottom-right",
        // autoClose: 3500,
        closeButton: <CloseButtonSuccess />,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
};
export const toastError = (msg, autoClose = true) => {
    const Foo = () => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span className="toast-marker toast-error"></span>
            {/* <img
				src="/icons/toast-error.svg"
				className="toast-icon"
				style={{ height: 20, width: 27.6 }}
				alt=""
			/> */}
            <p className="toast-text" style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                {msg}
            </p>
        </div>
    );
    toast.error(<Foo />, {
        position: "top-right",
        closeButton: <CloseButtonError />,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
};
export const toastWarning = (msg) => {
    const Foo = () => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span className="toast-marker toast-warning"></span>
            {/* <img
                src="/icons/toast-warning.svg"
                className="toast-icon"
                style={{ height: 20, width: 27.6 }}
                alt=""
            /> */}
            <p className="toast-text" style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                {msg}
            </p>
        </div>
    );
    toast.warning(<Foo />, {
        position: "top-right",
        // autoClose: 3500,
        closeButton: <CloseButtonWarning />,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
};

export const toastInfo = (msg, position) => {
    const Foo = () => (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <span className="toast-marker toast-info"></span>
            {/* <img
                src="/icons/toast-info.svg"
                className="toast-icon"
                style={{ height: 20, width: 27.6 }}
                alt=""
            /> */}
            <p className="toast-text" style={{ flex: 1, marginLeft: 10, marginRight: 10 }}>
                {msg}
            </p>
        </div>
    );
    toast.info(<Foo />, {
        position: "bottom-right",
        // autoClose: 3500,
        closeButton: <CloseButtonInfo />,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    });
};
