import React from "react";

const Career = () => {
    return (
        <div>
            <div className="container section-padding mb-5">
                <div className="intro">
                    <h1 className="regular-28 text-center mb-4">
                        “Join us in creating a better future”
                    </h1>
                    <p className="mb-3">
                        Rebuild Techno is a renowned e-commerce company specializing in refurbished
                        smartphones in Europe. As a following in the industry, we are constantly
                        seeking passionate and skilled individuals who share our vision of making a
                        positive impact. With our commitment to sustainability and excellence, we
                        offer an exciting opportunity for driven individuals to join our team and
                        contribute to the growth of our innovative company. Together, we can
                        redefine the future of technology while making a difference in the world.
                    </p>
                </div>

                <div className="about">
                    <h1 className="medium-20 mt-4 mb-2">About Rebuilt Techno</h1>
                    <p className="mb-3">
                        Rebuild Techno is a dynamic and innovative company operating in the
                        e-commerce industry, specializing in refurbished electronics. With a strong
                        presence in the European market, we are the number 1 growing brand for
                        refurbished phone. We are proud to have a diverse team of nearly 15
                        dedicated professionals who are passionate about technology and
                        sustainability.
                    </p>
                    <p className="mb-3">
                        At Rebuild Techno, we believe in giving used electronics a new lease on
                        life. Our mission is to provide customers with high-quality refurbished
                        devices while promoting environmental sustainability by reducing electronic
                        waste. We are committed to fostering a circular economy by extending the
                        lifespan of electronic devices through our meticulous refurbishment process.
                        With a commitment to quality and customer satisfaction, we offer a wide
                        range of refurbished smartphones, tablets, and other electronic devices from
                        leading brands.
                    </p>
                    <p className="mb-3">
                        What sets Rebuild Techno apart is our meticulous refurbishment process. Our
                        expert technicians thoroughly inspect, clean, and test each device to ensure
                        it meets our stringent quality standards. We take pride in offering reliable
                        and fully functional devices that provide an excellent user experience at an
                        affordable price.
                    </p>
                    <p className="mb-3">
                        As an international team, we value diversity and collaboration. Our
                        employees come from different backgrounds and cultures, bringing unique
                        perspectives and expertise to the table. We foster a supportive and
                        inclusive work environment where everyone is encouraged to contribute their
                        ideas and grow both personally and professionally.
                    </p>
                    <p className="mb-3">
                        We understand the importance of customer satisfaction, which is why we
                        strive to deliver exceptional service. From fast and secure shipping to
                        hassle-free returns and responsive customer support, we go the extra mile to
                        ensure our customers have a positive experience.
                    </p>
                    <p className="mb-3">
                        If you are looking to join a dynamic and forward-thinking company that
                        values sustainability, innovation, and teamwork, Rebuild Techno offers
                        exciting opportunities for growth and development. Join our diverse team and
                        be a part of reshaping the future of the electronics industry while making a
                        positive impact on the environment.
                    </p>
                </div>

                <div className="pursue">
                    <h1 className="medium-20 mt-4 mb-3">
                        Why pursue your career with Rebuild Techno?
                    </h1>
                    <ol type="1" className="mt-2">
                        <li className="mb-2">
                            <span className="weight-600">Impactful Work:</span> At Rebuild Techno,
                            you'll have the opportunity to make a real impact by contributing to
                            sustainable technology solutions. Join us in reducing electronic waste,
                            carbon footprint and contributing creating a green world.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Growth and Development:</span> We
                            prioritize both personal and professional growth. By joining our team,
                            you'll gain access to continuous learning and development opportunities,
                            allowing you to enrich your skills and progress your career within the
                            global tech industry, all within an international work setting.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Innovation and Technology:</span> Rebuild
                            Techno operates at the forefront of technological advancements. By
                            joining us, you'll be part of a dynamic environment where innovation is
                            encouraged, and you'll have the chance to work with cutting-edge
                            technologies.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Collaborative Culture:</span>We foster a
                            collaborative and inclusive culture where teamwork and idea-sharing are
                            highly valued. You'll have the chance to work alongside talented
                            individuals who are passionate about their work and driven to achieve
                            shared goals.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Customer-Centric Approach:</span> Rebuild
                            Techno is committed to delivering exceptional customer experiences. As a
                            member of our team, you'll play a crucial role in ensuring customer
                            satisfaction and helping us maintain our reputation as a trusted
                            provider of refurbished technology.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Work-Life Balance:</span>We understand the
                            importance of work-life balance and strive to create a supportive and
                            flexible work environment. We believe in the well-being of our employees
                            and promote healthy work-life integration.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Competitive Benefits: </span>We offer
                            competitive compensation packages and benefits to our employees. Your
                            hard work and contributions will be recognized and rewarded
                            appropriately.
                        </li>
                    </ol>
                    <p className="mb-3">
                        Join Rebuild Techno and work for making a green earth and be part of a
                        purpose-driven organization that combines technology, sustainability, and
                        customer satisfaction. Together, we can make a difference in the world of
                        refurbished technology.
                    </p>
                </div>

                <div className="values">
                    <h1 className="medium-20 mt-4 mb-3">Our Core Company Values</h1>
                    <ol type="1" className="mt-2">
                        <li className="mb-2">
                            <span className="weight-600">Sustainability:</span> We are committed to
                            promoting sustainability in the tech industry. We strive to reduce
                            electronic waste and offer affordable, refurbished technology solutions
                            that have a positive environmental impact.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Environmental Impact:</span> We recognize
                            the importance of environmental stewardship and strive to minimize our
                            carbon footprint. By refurbishing devices and promoting the circular
                            economy, we reduce the demand for new electronics and help conserve
                            valuable resources.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Quality:</span> We prioritize delivering
                            high-quality products and services to our customers. Our rigorous
                            testing and inspection processes ensure that all refurbished devices
                            meet our stringent standards of quality and functionality.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Customer Satisfaction:</span> Customer
                            satisfaction is at the heart of everything we do. We go the extra mile
                            to provide exceptional customer experiences, from fast and secure
                            shipping to hassle-free returns and responsive customer support.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Innovation:</span> We embrace innovation
                            and stay at the forefront of technological advancements. By continuously
                            exploring new solutions and approaches, we provide our customers with
                            the latest and most reliable refurbished devices.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Integrity:</span> We conduct our business
                            with integrity, transparency, and ethical practices. We value honesty,
                            trustworthiness, and accountability in all our interactions with
                            customers, partners, and employees.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Collaboration: </span>Collaboration is key
                            to our success. We foster a collaborative culture that encourages
                            teamwork, open communication, and the sharing of ideas. We believe that
                            diverse perspectives and collective efforts lead to better outcomes.
                        </li>
                        <li className="mb-2">
                            <span className="weight-600">Continuous Improvement: </span> We are
                            dedicated to continuous improvement in all aspects of our business. We
                            seek opportunities to learn, grow, and adapt to changing customer needs
                            and market trends, ensuring that we always deliver the best possible
                            experience.
                        </li>
                    </ol>
                    <p className="mb-3">
                        These core company values guide our actions, decisions, and interactions,
                        and they shape the way we operate and serve our customers.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Career;
