import React from "react";
import ReactImageMagnify from "react-image-magnify";
import img1 from "../../assets/images/buy.jpg";

const Test = () => {
    return (
        <div style={{ width: "80%" }}>
            <ReactImageMagnify
                {...{
                    smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        width: 500,
                        height: 400,
                        src: img1,
                    },
                    largeImage: {
                        src: img1,
                        width: 1000,
                        height: 800,
                    },
                    enlargedImageContainerStyle: {
                        zIndex: "1500",
                    },
                    enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%",
                    },
                }}
            />
        </div>
    );
};

export default Test;
