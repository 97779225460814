import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import { formatDistanceToNow } from 'date-fns';

const CustomerReview = () => {
    const [reviewList, setReviewList] = useState([]);

    const getReviewList = () => {
        let param = '?sort=rating,id,DESC';
        Http.GET("getReviewList", param )
            .then((response) => {
                const data = response.data;
                setReviewList(data.data);
            })
            .catch((response) => {
                toastError(response.message);
            });
    };

    const getReviewTimeAgo=( date = new Date()  ) => {
        const utcDate = new Date( date );
        const clientTime = new Date(utcDate.toLocaleString());
        return formatDistanceToNow( clientTime, { addSuffix: true });
    }

    useEffect(() => {
        getReviewList();
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className="cusomer-review-slider">
            <Slider {...settings}>
                {reviewList.length > 0 &&
                    reviewList.map((item, index) => (
                        <div className="single-item" key={"customer_review" + item.id}>
                            <div className="stars">
                                {Array.from({ length: item?.rating }).map((_, i) => (
                                    <FaStar key={i} color={"#00B67A"} size={20} />
                                ))}
                            </div>
                            <h3 className="regular-16 weight-500">{item?.title}</h3>
                            <p className="desc regular-14">{item?.remarks}</p>
                            <p className="author regular-12">
                                <span className="weight-600">{item?.userResponse?.name},</span>
                                <span className="dark_02"> { getReviewTimeAgo( item?.createdAt ) }</span>
                            </p>
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default CustomerReview;
