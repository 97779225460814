export const addToCartCount = (count) => {
    return {
        type: "ADDTOCART",
        payload: count,
    };
};
export const emptyCartCount = () => {
    return {
        type: "EMPTYCARTCOUNT",
    };
};
export const removeOne = (count) => {
    return {
        type: "REMOVEONE",
        payload: count,
    };
};
// export const remove = (product) => {
//     return {
//         type: "REMOVE",
//         payload: product,
//     };
// };

export default addToCartCount;
