import React, { useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import GoogleTranslate from "./googleTranslator";
import ErrorBoundary from "../utils/ErrorBoundary";

const DefaultLayout = () => {


    return (
        <ErrorBoundary>
            <GoogleTranslate/>
            <Header />
            <Outlet />
            <Footer />
        </ErrorBoundary>
    );
};

export default DefaultLayout;
