import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import Http from "../../utils/Http";
import { useLocation, useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../utils/Toast";
import { CART_SESSION_NAME } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { getShortName } from "../../utils/Utils";
import LoaderComponent from "../../utils/LoaderComponent";

const Review = () => {
    const [cartItems, setCartItems] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const getCartItems = () => {
        Http.GET("getOrderData", id)
            .then((response) => {
                const orderData = response.data;
                setCartItems(orderData.itemResponseList);
            })
            .catch((response) => {
                toastError(response.message);
            });
    };

    const handleRatingChange = (index, rating) => {
        setCartItems((prevCartItems) => {
            const updatedCartItems = [...prevCartItems];
            updatedCartItems[index].rating = rating;
            return updatedCartItems;
        });
    };

    const onFileUpload = (e, productId) => {
        let file = e.target.files[0];
        let data = {
            name: file.name,
            docMimeType: file.type,
            documentType: "CUSTOMER_REVIEW",
            base64Str: "",
            size: file.size,
        };
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            data.base64Str = reader.result;
            onAdd(productId, data); // Pass the product ID as an argument
        };
        reader.onerror = function (error) {
            toastError(error);
        };
    };

    const onAdd = async (productId, data) => {
        setLoading(true);
        await Http.POST("docAdd", data)
            .then(({ data }) => {
                setLoading(false);
                toastSuccess("Successfully Uploaded.");
                setUploadedFiles((prevUploadedFiles) => {
                    return {
                        ...prevUploadedFiles,
                        [productId]: [...(prevUploadedFiles[productId] || []), data],
                    };
                });

                // Set the documentIds in the cartItems state for the corresponding product
                setCartItems((prevCartItems) => {
                    const updatedCartItems = [...prevCartItems];
                    const productIndex = updatedCartItems.findIndex(
                        (item) => item.productResponse.id === productId
                    );
                    if (productIndex !== -1) {
                        updatedCartItems[productIndex].documentIds =
                            updatedCartItems[productIndex].documentIds || [];
                        updatedCartItems[productIndex].documentIds.push(data.documentIds);
                    }
                    return updatedCartItems;
                });
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request was unsuccessful.");
                }
            });
    };

    const submitReview = (productId) => {
        const productData = cartItems.find((item) => item.productResponse.id === productId);

        const documentIds = uploadedFiles[productId]?.map((file) => file.id) || [];

        const formData = {
            orderId: id,
            orderItemId: productData.id,
            rating: productData.rating || 0,
            remarks: productData.remarks || "",
            documentIds: documentIds,
        };

        Http.POST("addReview", formData)
            .then(({ data }) => {
                toastSuccess(data.message);
                // navigate("/profile?activeTab=ORDER");
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request wasn't successful");
                }
            });
    };

    useEffect(() => {
        getCartItems();
    }, []);

    return (
        <LoaderComponent loading={loading}>
            <div className="container section-padding">
                <h1 className="regular-28 mb-2 mb-3">Please share your review</h1>
                <div className="all-review-products">
                    {cartItems.length > 0 &&
                        cartItems.map((item, index) => (
                            <div
                                className="single-review-product d-flex justify-content-between align-items-center"
                                key={item.productResponse?.id}
                            >
                                <div className="image-info d-flex align-items-center">
                                    {" "}
                                    <img
                                        src={item?.productResponse?.featureImageResponse?.docUrl}
                                        alt="feature-image"
                                    />
                                    <div className="info-description">
                                        <p className="regular-14 mb-1">
                                            {item?.productResponse?.name}
                                        </p>
                                        <p className="regular-12 dark_02">
                                            {item?.productResponse?.brandResponse?.name}
                                        </p>
                                    </div>
                                </div>

                                <div className="rating">
                                    {Array.from({ length: 5 }).map((_, i) => (
                                        <FaStar
                                            key={i}
                                            color={i < item.rating ? "#00B67A" : "#cbcbcb"}
                                            size={20}
                                            onClick={() => handleRatingChange(index, i + 1)}
                                        />
                                    ))}
                                </div>
                                <div className="file-upload">
                                    <div className="upload">
                                        <label
                                            htmlFor={`uploadAttachment-${item.productResponse?.id}`}
                                            className="drag-upload cursor-pointer mr-2 pr-1"
                                        >
                                            <span className="d-flex align-items-center">
                                                <img
                                                    src="/icons/upload.svg"
                                                    alt="upload"
                                                    className="upload-icon"
                                                />
                                                <span className="regular-14 dark_01 ms-1">
                                                    Upload
                                                </span>
                                            </span>
                                        </label>

                                        <input
                                            type="file"
                                            id={`uploadAttachment-${item.productResponse?.id}`}
                                            onClick={(e) => (e.target.value = null)}
                                            onChange={(e) =>
                                                onFileUpload(e, item.productResponse?.id)
                                            }
                                            multiple={false}
                                        />
                                    </div>
                                    <div className="uploaded-files">
                                        {uploadedFiles[item.productResponse?.id]?.map((file, i) => (
                                            <p
                                                key={i}
                                                className="regular-12 dark_01"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content={file?.name}
                                                onClick={() => window.open(file?.docUrl, "_blank")}
                                            >
                                                {getShortName(file?.name, 20)}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                                <div className="description">
                                    <textarea
                                        maxLength={300}
                                        value={item.remarks || ""}
                                        onChange={(e) => {
                                            setCartItems((prevCartItems) => {
                                                const updatedCartItems = [...prevCartItems];
                                                updatedCartItems[index].remarks = e.target.value;
                                                return updatedCartItems;
                                            });
                                        }}
                                    />
                                </div>
                                <div className="submit">
                                    <button
                                        className="button green"
                                        onClick={() => submitReview(item.productResponse?.id)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </LoaderComponent>
    );
};

export default Review;
