import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import { useNavigate } from "react-router-dom";
import { getShortName } from "../../utils/Utils";

const ProductSlider = () => {
    const [exclusiveProductList, setExclusiveProductList] = useState([]);
    const navigate = useNavigate();

    const getExclusiveProductList = () => {
        Http.GET("exclusiveProductList")
            .then((response) => {
                let data = response.data;
                setExclusiveProductList(data.data);
            })
            .catch((response) => {
                toastError("Something went wrong!");
            });
    };

    const goToProduct = (slug) => {
        //navigate(`/products/${slug}`);
        window.location.href = `/products/${slug}`;
    };

    const settings = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        getExclusiveProductList();
    }, []);
    return (
        <div className="product-slider">
            <Slider {...settings}>
                {exclusiveProductList &&
                    exclusiveProductList.map((item) => (
                        <div
                            className="single-product"
                            key={item.id}
                            onClick={() => goToProduct(item?.slug)}
                        >
                            <img
                                src={
                                    item?.featureImageResponse?.docUrl ||
                                    "/images/phone-defauld.png"
                                }
                                className="product-image"
                                alt="product"
                            />
                            <div className="name-info">
                                <h3 data-tooltip-id="my-tooltip" data-tooltip-content={item?.name}>
                                    {getShortName(item?.name, 15)}
                                </h3>

                                <p>
                                    €
                                    {(
                                        item?.price -
                                        ((item?.discountPercentage || 0) / 100) * item?.price
                                    ).toFixed(2)}
                                    {item?.discountPercentage && (
                                        <del>({item?.price?.toFixed(2)})</del>
                                    )}
                                </p>
                            </div>
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default ProductSlider;
