import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="container section-padding mb-5">
            <h1 className="regular-28 text-center mb-4">Privacy Policy</h1>
            <p>
                At Rebuild Techno, we are committed to protecting your privacy and ensuring the
                security of your personal information. This Privacy & Policy section outlines how we
                collect, use, and safeguard the information you provide when using our website and
                services. By accessing and using our website, you agree to the terms and practices
                described in this Privacy & Policy statement.
            </p>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Collection of Personal Information:</h3>
                <p>
                    When you visit our website or make a purchase, we may collect certain personal
                    information from you, including but not limited to your name, email address,
                    shipping address, and payment details. This information is collected to process
                    your orders, provide customer support, and enhance your overall shopping
                    experience.
                </p>
            </div>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Use of Personal Information: </h3>
                <p>We use the personal information collected for the following purposes:</p>
                <ol type="a" className="mt-2">
                    <li>Processing and fulfilling your orders</li>
                    <li>
                        Communicating with you regarding your purchases, shipping updates, and
                        customer support
                    </li>
                    <li>
                        Personalizing your experience on our website and recommending relevant
                        products
                    </li>
                    <li>
                        Sending promotional offers, newsletters, and marketing communications (if
                        you opt-in to receive them)
                    </li>
                    <li>
                        Conducting research and analysis to improve our products, services, and
                        website functionality
                    </li>
                    <li>
                        Complying with legal requirements and protecting our rights and the rights
                        of our customers
                    </li>
                </ol>
            </div>
            <div>
                <h3 className="medium-20 mt-3 mb-2">Data Security:</h3>
                <p>
                    We take data security seriously and employ industry-standard measures to protect
                    your personal information from unauthorized access, disclosure, alteration, and
                    destruction. We use secure socket layer technology (SSL) to encrypt your
                    sensitive data during transmission. However, please note that no method of data
                    transmission over the internet or electronic storage is completely secure, and
                    we cannot guarantee absolute security.
                </p>
            </div>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Third-Party Disclosure:</h3>
                <p>
                    We may share your personal information with trusted third-party service
                    providers who assist us in operating our website, processing payments,
                    delivering orders, or conducting business analytics. These third-party service
                    providers have access to the necessary information only to perform their
                    functions and are obligated to maintain the confidentiality and security of your
                    personal data.
                </p>
            </div>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Cookies and Tracking Technologies:</h3>
                <p>
                    Our website may use cookies and similar tracking technologies to enhance your
                    browsing experience, personalize content, and gather information about your
                    usage patterns. You have the option to accept or decline cookies through your
                    browser settings. However, disabling cookies may affect your ability to access
                    certain features and functionality on our website.
                </p>
            </div>
            <div>
                {" "}
                <h3 className="medium-20 mt-4 mb-2">Your Rights and Choices:</h3>
                <p>
                    You have the right to review, update, and delete the personal information we
                    hold about you. If you wish to exercise these rights or have any concerns
                    regarding the handling of your personal information, please contact our customer
                    support team. Additionally, you have the choice to opt out of receiving
                    marketing communications from us by following the unsubscribe instructions
                    provided in our emails.
                </p>
            </div>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Children's Privacy:</h3>
                <p>
                    Our website and services are not intended for individuals under the age of 18.
                    We do not knowingly collect personal information from children. If we become
                    aware that personal information has been collected from a child without parental
                    consent, we will take appropriate steps to delete that information.
                </p>
            </div>
            <div>
                <h3 className="medium-20 mt-4 mb-2">Updates to Privacy & Policy:</h3>
                <p>
                    We may update this Privacy & Policy section from time to time to reflect changes
                    in our practices or legal requirements. We encourage you to review this section
                    periodically for any updates. Your continued use of our website after any
                    changes signifies your acceptance of the revised Privacy & Policy.
                </p>
                <p className="mt-2">
                    If you have any questions or concerns about our Privacy & Policy, please contact
                    our customer support team, and we will be happy to assist you.
                </p>
                <p className="dark_02 mt-3">Last updated: [Jue 09, 2023]</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
