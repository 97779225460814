import React from "react";
import { useState, useRef } from "react";
import Http from "../../utils/Http";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isPageReachBottom } from "../../utils/Utils";
import LoaderComponent from "../../utils/LoaderComponent";
import { toastError, toastSuccess } from "../../utils/Toast";
import { CART_SESSION_NAME } from "../../utils/constant";
import { useDispatch } from "react-redux";
import addToCartCount from "../../actions/action";

const CategoryDetails = () => {
  const [productList, setProductList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [brandIds, setBrandIds] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("id,desc");
  const [showFilter, setShowFilter] = useState();
  const [searchBrand, setSearchBrand] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [selectedColorId, setSelectedColorId] = useState(null);
  const navigate = useNavigate();
  const { type, id } = useParams();
  const uppercaseType = type.toUpperCase();
  const myStateRef = useRef({});
  const dispatch = useDispatch();

  const setMyState = (data) => {
    myStateRef.current = data;
  };

  const getCartSession = () => {
    return localStorage.getItem(CART_SESSION_NAME);
  };

  const addToCart = (event, product) => {
    event.stopPropagation();
    const formData = {
      productId: product.id,
      colorId: selectedColorId,
      quantity: 1,
      price: product.price,
      session: getCartSession(),
    };
    Http.POST("addToCart", formData)
      .then(({ data }) => {
        localStorage.setItem(CART_SESSION_NAME, data.session);
        dispatch(addToCartCount({ count: 1 }));
        toastSuccess("Product added on cart");
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.message) {
          toastError(response.data.message);
        } else {
          toastError("Request wasn't successful");
        }
      });
  };

  const getCategoryList = () => {
    let param = `?type=${uppercaseType}&isShowOnCustomer=true&search=${searchCategory}`;
    Http.GET("categoryList", param)
      .then((response) => {
        let data = response.data;
        setcategoryList(data.data);
      })
      .catch((response) => {
        //toastError("Something went wrong!");
      });
  };

  const getBrandList = () => {
    Http.GET("brandList", `?name=${searchBrand}`)
      .then((response) => {
        let data = response.data;
        setBrandList(data.data);
      })
      .catch((response) => {
        //toastError("Something went wrong!");
      });
  };

  const getProductsList = () => {
    setLoading(true);
    let param = `?page=${page}&size=9&sort=${sort}&category_type=${uppercaseType}`;
    let catIds = categoryIds;
    if ( catIds != null && catIds.length > 1 ) {
      catIds = catIds.filter( catId => catId !== id );
    }
    if (catIds != null && catIds.length > 0)
      param += "&categoryIds=" + catIds.join(",");

    if (brandIds != null && brandIds.length > 0)
      param += "&brandIds=" + brandIds.join(",");
    if (search != null && search !== "") param += "&search=" + search;

    Http.GET("getCategoryProducts", param)
      .then((response) => {
        let data = response.data;
        const mergedProductList =
          page === 0 ? data.data : [...productList, ...data.data];
        setProductList(mergedProductList);
        setMyState(response.data);
        setLoading(false);
      })
      .catch((response) => {
        //toastError("Something went wrong!");
      });
  };

  const changeCategoryId = (isChecked, id) => {
    let ids = [...categoryIds];
    if (isChecked) ids.push(id);
    else {
      ids = ids.filter((item) => item !== id);
    }
    setPage(0);
    setCategoryIds(ids);
  };

  const changeBrandId = (isChecked, id) => {
    let ids = [...brandIds];
    if (isChecked) ids.push(id);
    else {
      ids = ids.filter((item) => item !== id);
    }
    setPage(0);
    setBrandIds(ids);
  };

  const changeSort = (val) => {
    setPage(0);
    setSort(val);
  };

  const goToProduct = (slug) => {
    //navigate(`/products/${slug}`,{replace : true});
    window.location.href = `/products/${slug}`;
  };

  const handleScroll = () => {
    if (isPageReachBottom() && myStateRef && myStateRef.current) {
      let { totalElements, currentPage, data, totalPages } = myStateRef.current;
      if (totalElements && totalElements > 0 && totalElements !== data.length) {
        if (currentPage === totalPages) return;
        setPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getProductsList();
  }, [type]);

  useEffect(() => {
    getCategoryList();
  }, [type, searchCategory]);

  useEffect(() => {
    getBrandList();
  }, [type, searchBrand]);

    useEffect(() => {
      if( id && id !== 0 ){
        setCategoryIds( [] );
        setTimeout( () => {
          let ids = [];
          ids.push( id );
          setPage(0);
          setCategoryIds(ids);

        } , 300);
      }
    }, [id]);

  useEffect(() => {
    getProductsList();
  }, [categoryIds, sort, brandIds, search, page]);

  return (
    <LoaderComponent loading={loading}>
      <div className="container category-details">
        <div className="d-flex filter-with-products">
          <div
            className={`${showFilter ? "filter-items show" : "filter-items"}`}
          >
            <div className="brand-and-category">
              <div className="category border-bottom-solid pb-3 mb-2">
                <img
                  src="/icons/close.svg"
                  alt="close"
                  className="close-filter cursor-pointer"
                  onClick={() => setShowFilter(false)}
                />
                <div className="title d-flex justify-content-between align-items-center mb-2 mb-2">
                  <h3 className="regular-16 weight-500">Category</h3>
                </div>
                <div className="d-flex search-box align-items-center mb-1">
                  <input
                    type="text"
                    className="form-control form-control-sm common-list-search"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchCategory}
                    onChange={(e) => setSearchCategory(e.target.value)}
                  />

                  <img
                    src="/icons/search.svg"
                    alt="search"
                    className="search"
                  />
                </div>

                <div className="custom-chekbox  scroll-y-label">
                  {categoryList &&
                    categoryList.map((item) => (
                      <div className="form-group" key={"category-" + item.id}>
                        <input
                          type="checkbox"
                          id={item?.id}
                          name={item?.name}
                          onChange={(e) =>
                            changeCategoryId(e.target.checked, item.id)
                          }
                          defaultValue={item?.id}
                        />
                        <label htmlFor={item?.id}>
                          <span>{item?.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
              <div className="brand ">
                <div className="title d-flex justify-content-between align-items-center mb-2 mb-2">
                  <h3 className="regular-16 weight-500">Brand</h3>
                </div>
                <div className="d-flex search-box align-items-center mb-1">
                  <input
                    type="text"
                    className="form-control form-control-sm common-list-search"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchBrand}
                    onChange={(e) => setSearchBrand(e.target.value)}
                  />

                  <img
                    src="/icons/search.svg"
                    alt="search"
                    className="search"
                  />
                </div>
                <div className="custom-chekbox scroll-y-label">
                  {brandList &&
                    brandList.map((item) => (
                      <div className="form-group" key={"brand-" + item.id}>
                        <input
                          type="checkbox"
                          id={item?.id}
                          name={item?.name}
                          onChange={(e) =>
                            changeBrandId(e.target.checked, item.id)
                          }
                          defaultValue={item?.id}
                        />
                        <label htmlFor={item?.id}>
                          <span>{item?.name}</span>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="category-products mb-4">
            <div className="search-and-sort d-flex justify-content-end align-items-center">
              <img
                src="/icons/filter.svg"
                alt="filter"
                className="filter-icon cursor-pointer"
                onClick={() => setShowFilter(true)}
              />

              <div className="d-flex">
                <div className="d-flex search-box align-items-center category-product-search me-3">
                  <input
                    type="text"
                    className="form-control form-control-sm common-list-search"
                    placeholder="Search..."
                    aria-label="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    src="/icons/search.svg"
                    alt="search"
                    className="search"
                  />
                </div>
                { uppercaseType === 'BUY' && (
                    <div className="mb-3">
                      SORT BY
                      <select
                          name="sort"
                          value={sort}
                          onChange={(e) => changeSort(e.target.value)}
                          className="form-select form-select-sm"
                      >
                        <option value="id,desc">RECOMMENDED</option>
                        <option value="price,asc">PRICE LOW TO HIGH</option>
                        <option value="price,desc">PRICE HIGH TO LOW</option>
                      </select>
                    </div>
                ) }
              </div>
            </div>
            <div className="products-row">
              {productList &&
                productList.map((item) => (
                  <div
                    className="single-product"
                    key={item.id}
                    onClick={() => goToProduct(item?.slug)}
                  >
                    <img
                      src={item?.featureImageResponse?.docUrl}
                      className="product-image"
                      alt="product"
                    />
                    <div className="name-info d-flex justify-content-between align-items-center">
                      <h3>{item?.name}</h3>
                      {/* <p className="varient">256gb</p> */}
                    </div>
                    { uppercaseType === 'BUY' && (
                        <div className="condition mt-2">
                          <p>
                            Condition: <strong>{item?.condition}</strong>
                          </p>
                        </div>
                    )}

                    <div className="d-flex justify-content-between">
                      <h3 className="price">
                        €{item?.price ? item?.price : "N/A"}
                      </h3>
                      {item?.categoryType && item?.categoryType === "BUY" && (
                        <button
                          className="text-only"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={"Add to cart"}
                          onClick={(e) => addToCart(e, item)}
                        >
                          <img
                            src="/icons/shopping-bag.svg"
                            alt="shopping-bag"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            {productList?.length === 0 && (
              <p className="empty-state">No product found</p>
            )}
          </div>
        </div>
      </div>
    </LoaderComponent>
  );
};

export default CategoryDetails;
