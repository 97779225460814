import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CategorySlider = () => {
    const settings = {
        className: "center",
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        centerPadding: "60px",
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className="category-slider">
            <Slider {...settings}>
                <div className="single-item">
                    <img src="/images/top-cat-2.webp" alt="offer" />
                </div>
                <div className="single-item">
                    <img src="/images/top-cat-3.webp" alt="offer" />
                </div>
                <div className="single-item">
                    <img src="/images/top-cat-4.webp" alt="offer" />
                </div>
            </Slider>
        </div>
    );
};

export default CategorySlider;
