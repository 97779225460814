import React, { useEffect } from "react";
import "./assets/scss/app.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./modules/auth/Login";
import Home from "./modules/home/Home";
import DefaultLayout from "./layout/DefaultLayout";
import Register from "./modules/auth/Register";
import CategoryDetails from "./modules/category/CategoryDetails";
import PrivacyPolicy from "./modules/policy/PrivacyPolicy";
import ProductDetails from "./modules/product/ProductDetails";
import Cart from "./modules/cart/Cart";
import Profile from "./modules/profile/Profile";
import Checkout from "./modules/cart/Checkout";
import ScrollToTop from "./utils/ScrollToTop";
import Faq from "./modules/faq/Faq";
import AboutUs from "./modules/about/AboutUs";
import Review from "./modules/review/Review";
import Test from "./modules/review/Test";
import Career from "./modules/career/Career";
import RtReturnWarranty from "./modules/policy/RtReturnWarranty";
import GoogleTranslate from "./layout/googleTranslator";
import ProductCompare from "./modules/product/ProductCompare";
import VerifyEmail from "./modules/auth/VerifyEmail";

function App() {
    /*useEffect(() => {
        var addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: 'en',
                includedLanguages: 'en,bn,fi,ga,sv', // include this for selected languages
                //layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
            },
            'google_translate_element'
        );
    };*/
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    {/* <Route path="/" element={<Login />} /> */}
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/user/reset/password" element={<VerifyEmail />} />
                    <Route path="/" element={<DefaultLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/:type/:name/:id" element={<CategoryDetails />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/rt-return-warranty-policy" element={<RtReturnWarranty />} />
                        <Route path="/products/:slug" element={<ProductDetails />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/checkout" element={<Checkout />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/career" element={<Career />} />
                        <Route path="/review/:id" element={<Review />} />
                        <Route path="/product/compare" element={<ProductCompare />} />
                        <Route path="/test" element={<Test />} />
                    </Route>
                </Routes>
                {/*<GoogleTranslate />*/}
            </BrowserRouter>
        </>
    );
}

export default App;
