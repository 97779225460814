import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Http from "../../utils/Http";
import Select from "react-select";
import { BASE_URL } from "../../utils/constant";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toastError, toastSuccess } from "../../utils/Toast";

const AddAddress = ({ showEdit, setShowEdit, setIdToEdit, idToEdit, updateAddresses }) => {
    const [locationType, setLocationType] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [cityList, setCityList] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [city, setCity] = useState(null);
    const [locationInfo, setLocationInfo] = useState({
        locationType: "",
        city: null,
        state: "",
        zip: "",
        title: "",
        landMark: "",
        address: "",
        address2: "",
    });

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const id = userInfo?.id;

    const getCountryList = () => {
        Http.GET("getCountryList").then(({ data }) => {
            const formatData = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setCountryList(formatData);
        });
    };

    const getCityList = () => {
        Http.GET(`${BASE_URL}/api/location/country/${countryId}/city`, "", true).then(
            ({ data }) => {
                const formatData = data.map((item) => ({
                    value: item.id,
                    label: item.name,
                }));
                setCityList(formatData);
            }
        );
    };

    const getAddressData = async () => {
        console.log( "edit id", idToEdit );
        if (idToEdit !== null) {
            try {
                const response = await Http.GET(
                    `${BASE_URL}/api/location/${idToEdit}/my`,
                    "",
                    true
                );
                const data = response.data;
                setLocationInfo((prevLocation) => ({
                    ...prevLocation,
                    ...data,
                }));
                setCityId(data?.cityResponse?.id);
                setCity(data?.cityResponse?.name);
                setCountryId(data?.countryResponse?.id);
            } catch (error) {
                toastError(error.message);
            }
        }
    };

    const submitAddress = () => {
        const formData = {
            ...locationInfo,
            countryId: countryId,
            cityId: cityId,
            city: city.label,
        };

        Http.POST(`${BASE_URL}/api/location/${idToEdit}/my`, formData, "", true)
            .then((results) => {
                toastSuccess("Successfully updated");
                updateAddresses();
                setShowEdit(false);
            })
            .catch(({ response }) => {
                if (response && response.data && response.data.message) {
                    toastError(response.data.message);
                } else {
                    toastError("Request wasn't successful");
                }
            });
    };

    const handleClose = () => {
        setIdToEdit(null);
        setShowEdit(false);
        setLocationInfo({});
    };

    useEffect(() => {
        getAddressData();
    }, [ idToEdit ]);

    useEffect(() => {
        getCountryList();
    }, []);

    useEffect(() => {
        if (countryId) {
            getCityList();
        }
    }, [countryId]);

    return (
        <>
            <Modal show={showEdit} onHide={handleClose} className="add-address-modal">
                <Modal.Header closeButton>
                    <h3 className="modal-title">Edit address</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="address-container">
                        <div className="address-row">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="locationType" className="form-label mb-0">
                                            Location Type
                                        </label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={locationInfo.locationType}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    locationType: event.target.value,
                                                }))
                                            }
                                        >
                                            <option value={locationInfo.type} selected>
                                                {locationInfo.type}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline no-shadow-react-select flex-fill mb-0 mb-3">
                                        <label htmlfor="countryId" className="form-label mb-0">
                                            Country
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCountryId(selectedOption.value);
                                                setCityId(null);
                                            }}
                                            name="country"
                                            value={countryList.find(
                                                (option) => option.value === countryId
                                            )}
                                            options={countryList}
                                            isClearable={false}
                                            placeholder="Select a country..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="no-shadow-react-select form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="city" className="form-label mb-0">
                                            City
                                        </label>
                                        <Select
                                            onChange={(selectedOption) => {
                                                setCityId(selectedOption.value);
                                                setCity(selectedOption.label);
                                            }}
                                            name="city"
                                            value={cityList.find(
                                                (option) => option.value === cityId
                                            )}
                                            options={cityList}
                                            isDisabled={!countryId}
                                            isClearable={false}
                                            placeholder={
                                                !countryId
                                                    ? "Please select a country first"
                                                    : cityList.length > 0
                                                    ? "Select a city..."
                                                    : "No cities available"
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="state" className="form-label mb-0">
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            name="state"
                                            value={locationInfo.state}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    state: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="zip" className="form-label mb-0">
                                            ZIP
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="zip"
                                            name="zip"
                                            value={locationInfo.zip}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    zip: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    {" "}
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="title" className="form-label mb-0">
                                            Title
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="title"
                                            name="title"
                                            value={locationInfo.title}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    title: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="landMark" className="form-label mb-0">
                                            Land Mark
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="landMark"
                                            name="landMark"
                                            value={locationInfo.landMark}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    landMark: event.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="address" className="form-label mb-0">
                                            Address
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationInfo.address}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    address: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-outline flex-fill mb-0 mb-3">
                                        <label htmlfor="addressTwo" className="form-label mb-0">
                                            Address 2
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id=""
                                            value={locationInfo.address2}
                                            onChange={(event) =>
                                                setLocationInfo((prevLocation) => ({
                                                    ...prevLocation,
                                                    address2: event.target.value,
                                                }))
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                        <button type="submit" className="button secondary" onClick={handleClose}>
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="button green ms-3"
                            onClick={() => submitAddress()}
                        >
                            Submit
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddAddress;
