import React, { useEffect, useState } from "react";
import Http from "../../utils/Http";
import { toastError } from "../../utils/Toast";
import { useLocation } from 'react-router-dom';

const Faq = () => {
    const [faqList, setFaqList] = useState([]);
    const [type, setType] = useState("BUY");
    const [search, setSearch] = useState("");

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const querySearch = queryParams.get('query');

    const getFaqList = async () => {
        let param = `?type=GENERAL,${type}`;
        if (search !== "") {
            param += '&search=' + search;
        }

        try {
            const response = await Http.GET("faqList", param);
            let data = response.data;
            setFaqList(data.data);
        } catch (error) {
            toastError("Something went wrong!");
        }
    };

    useEffect(() => {
        getFaqList();
    }, [type, search]);

    useEffect(() => {
        console.log( querySearch );
        if( querySearch !== null && querySearch !== undefined && querySearch !== '' ){
            setSearch( querySearch );
        }
    }, [ querySearch ]);

    return (
        <div className="container section-padding">
            <div className="faq-questions">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1 className="regular-28 text-uppercase">FAQs</h1>
                    <div className="d-flex search-box align-items-center mb-1">
                        <input
                            type="text"
                            className="form-control form-control-sm common-list-search"
                            placeholder="Search..."
                            aria-label="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <img
                            src="/icons/search.svg"
                            alt="search"
                            className="search"
                        />
                    </div>

                    <div>
                        <select
                            className="form-select bg-transparent"
                            aria-label="Default select example"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                            <option value="REPAIR">REPAIR</option>
                        </select>
                    </div>
                </div>

                <div className="accordion" id="accordionExample">
                    {faqList &&
                        faqList.map((item, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={`heading${index}`}>
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`}
                                        aria-expanded="true"
                                        aria-controls={`collapse${index}`}
                                    >
                                        {item?.title}
                                    </button>
                                </h2>
                                <div
                                    id={`collapse${index}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`heading${index}`}
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">{item?.description}</div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Faq;
