import React, { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../utils/Toast";
import Modal from "react-bootstrap/Modal";
import Http from "../../utils/Http";

const PersonalInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [error, setError] = useState({});
    const id = userInfo.id;

    const validate = () => {
        const errors = {};

        if (!currentPassword) {
            errors.currentPassword = "Current password is required.";
        }

        if (!password) {
            errors.password = "Password is required.";
        }

        if (!retypePassword) {
            errors.retypePassword = "Please retype password.";
        }

        if( password !== retypePassword )
            errors.retypePassword = "Confirm password should same as password.";
        setError(errors);

        return Object.keys(errors).length === 0;
    };

    const updatePassword = () => {
        if (validate()) {
            const data = {
                currentPassword,
                password,
                retypePassword,
            };
            Http.POST("updatePassword", data)
                .then((response) => {
                    toastSuccess("Password updated successfully");
                    setShow(false);
                    setCurrentPassword("");
                    setPassword("");
                    setRetypePassword("");
                })
                .catch(({ response }) => {
                    let data = JSON.parse( response.data.message );
                    let message = response.data.message;
                    if( data?.length > 0 && data[0]?.defaultMessage != undefined ) {
                        message = data[0]?.defaultMessage;
                    }
                    toastError( message );
                });
        }
    };

    return (
        <div className="personal-information d-flex flex-wrap justify-content-between">
            <div className="pe-4">
                <p className="regular-16 dark_02 mb-2">
                    Name: <span className="dark_01">{userInfo?.name}</span>
                </p>
                <p className="regular-16 dark_02 mb-2">
                    Email: <span className="dark_01">{userInfo?.email}</span>
                </p>
                <p className="regular-16 dark_02 mb-2">
                    Phone: <span className="dark_01">{userInfo?.phone}</span>
                </p>
            </div>
            <div>
                <button className="button green" onClick={() => setShow(true)}>
                    Change Password
                </button>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h3 className="modal-title">Updatae password</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row">
                        <div className="container px-0">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="password" className="form-label mb-0">
                                        Current Password
                                    </label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        value={currentPassword}
                                        name="password"
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                    />
                                    {error.currentPassword && (
                                        <p className="text-danger regular-12">
                                            {error.currentPassword}
                                        </p>
                                    )}
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label htmlFor="password" className="form-label mb-0">
                                        Password
                                    </label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        value={password}
                                        name="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {error.password && (
                                        <p className="text-danger regular-12">{error.password}</p>
                                    )}
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="retypePassword" className="form-label mb-0">
                                        Confirm Password
                                    </label>
                                    <input
                                        className="form-control"
                                        type="password"
                                        value={retypePassword}
                                        name="retypePassword"
                                        onChange={(e) => setRetypePassword(e.target.value)}
                                    />
                                    {error.retypePassword && (
                                        <p className="text-danger regular-12">
                                            {error.retypePassword}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="button secondary" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="button green ms-2"
                        onClick={() => updatePassword()}
                    >
                        Update
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PersonalInfo;
